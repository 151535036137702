import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { GET_PAGE_SITE_QUERY } from 'src/libs/apis/graphql/queries/page.query'
import { convertAdminAssetUrl } from 'src/libs/utils/url'
import Button from '../ui/Button'

interface ReChargeWithDrawInfoProps {
  title: string
  titleButton: string
}

export default function ReChargeWithDrawInfo(props: ReChargeWithDrawInfoProps) {
  const [isShowDetail, setIsShowDetail] = useState(false)
  const { data: pageSiteQuery } = useQuery(GET_PAGE_SITE_QUERY, {
    context: { apiName: 'auth' }
  })

  return (
    <div
      className={`rounded-md bg-[#101216] w-full xl:max-w-[535px] hidden md:flex flex-col ${isShowDetail ? 'py-5 px-10' : 'justify-center items-center'}`}
    >
      {isShowDetail ? (
        <div dangerouslySetInnerHTML={{ __html: props.title }} />
      ) : (
        // <h3 className='text-[#DC992E] text-18 font-bold'>입금신청시 안내사항</h3>
        // <p>※ 최소 입금금액</p>
        // <p className='mt-5'>최소 금액 10,000원부터 입금 가능하시며,</p>
        // <p>100원, 1,000원 단위로는 입금 처리가 불가능합니다.</p>
        // <p className='mt-5'>※ 입금 방법</p>
        // <p className='mt-5'>빠른계좌문의 ▶ 입금 ▶ 보너스 선택 ▶ 입금 신청</p>
        // <p>순서대로 진행해 주시면 신속하게 처리됩니다. </p>
        // <p className='mt-5'>※ 입금 주의사항</p>
        // <p className='mt-5'>1. 수표 입금 및 타인 명의로 입금 시 이용이 제한됩니다.</p>
        // <p>2. 토스, 핀크, 카카오페이 등의 간편 송금 서비스를</p>
        // <p>지원하지 않습니다.</p>
        // <p className='mt-5'>※ 입금 지연 및 취소 처리</p>
        // <p className='mt-5'>1. 입금자 명이 일치 하지 않는 경우</p>
        // <p>2. 100원, 1,000원 단위를 입금 하신 경우</p>
        // <p>3. 충전 금액과 신청 하신 금액이 일치 하지 않는 경우</p>
        // <p className='mt-5'>&quot; 보너스 지급 &quot;</p>
        // <p className='mt-5'>스포츠 100%</p>
        // <p>미니게임 300%</p>
        // <p>카지노 500%</p>
        // <p className='mt-5'>&quot; 보너스 미지급 &quot;</p>
        // <p>스포츠 100%</p>
        // <p>미니게임 100%</p>
        // <p>카지노 200%</p>
        <div className='py-2'>
          {pageSiteQuery?.PageSiteQuery?.siLogo1 && (
            <img
              src={pageSiteQuery?.PageSiteQuery?.siLogo1}
              alt='Logo'
              title='logo'
              width='327'
              height='161'
              onError={(e) => {
                e.currentTarget.src = '/zero.png'
                e.currentTarget.onerror = null
              }}
              className='max-h-[161px] object-contain max-w-[327px]'
            />
          )}
          {/* <p className='mt-5'>{props.title ?? '신속한 입출금, 최소한의 제재'}</p> */}
          <p className='mt-5 text-center'>{'신속한 입출금, 최소한의 제재'}</p>
          <Button
            className='h-10 rounded bg-primary text-black mt-5 w-fit px-4 min-w-20 font-medium text-14 flex justify-center items-center gap-2 mx-auto'
            type='button'
            onClick={() => setIsShowDetail(true)}
          >
            {props?.titleButton}
          </Button>
        </div>
      )}
    </div>
  )
}
