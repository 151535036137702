import cn from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { koreanImages } from 'src/components/SideRightMiniGame/SideRightMiniGame'
import { useCartMiniGameStore } from 'src/libs/hooks/store/useCartMiniGameStore'
import { useViewport } from 'src/libs/hooks/useWindowDimension'
import { addItemToCartMiniGame, BetMiniGameItem, removeAllCartMiniGame } from 'src/libs/stores/minigame.reducer'
import {
  convertMapToArrayMiniGame,
  formatTimeFromSeconds,
  groupDataOptionsByCategory,
  groupDataOptionsByTab
} from 'src/libs/utils/minigame.func'
import { GameRes, OptionMiniGameItem } from 'src/types/minigame.type'

type Props = {
  data: GameRes
  loading?: boolean
  handleRefetchGame: () => Promise<void>
}

export const BetItemBoscorePowerBall = (data: {
  title: string
  listOption: OptionMiniGameItem[]
  transId: string
  isLock: boolean
}) => {
  const { cart } = useCartMiniGameStore()
  const dispatch = useDispatch()
  const { width } = useViewport()

  const addToCart = ({ betMiniGameItem }: { betMiniGameItem: BetMiniGameItem }) => {
    if (data.isLock) {
      toast.error('Locking time')
      return
    }
    // implement when BE implement multi choose in 1 request
    // if (cart.pick_list.length === MAXIMUM_BET_ONE_TIME) {
    //   toast.warning('Can not pick')
    // }
    dispatch(addItemToCartMiniGame(betMiniGameItem))
  }

  return (
    <div className='grid grid-cols-1 md-up:grid-cols-[2fr_10fr] gap-4 py-1 px-2'>
      <div className='cols-span-2 flex items-center justify-center bg-mini-game-table-4 text-mini-game-table-2 text-14'>
        {data.title.split(' ').map((word, index) => (
          <React.Fragment key={index}>
            {word}
            {index < data.title.split(' ').length - 1 && <br />}
          </React.Fragment>
        ))}
      </div>
      <div
        className='cols-span-10 grid  gap-x-6 gap-y-3'
        style={{
          gridTemplateColumns: `repeat(${data.listOption.length > 5 ? 5 : data.listOption.length}, 1fr)`
        }}
      >
        {data.listOption.map((option) => {
          return (
            <button
              onClick={() => {
                addToCart({
                  betMiniGameItem: {
                    selectedKeyItem: option.key || '',
                    miniGameItem: {
                      category: option.category_text || '',
                      rate: option.rate || 0,
                      text: option.text || '',
                      transId: data.transId
                    }
                  }
                })
              }}
              key={option.key}
              className={cn(
                'flex items-center justify-center h-[100px] gap-2 border border-mini-game-table-border relative group duration-150'
              )}
            >
              <span
                className={cn('flex items-center justify-center relative top-[15px] w-full h-[calc(100%-30px)]', {
                  'bg-mini-game-table-item hover:bg-mini-game-table-item-active':
                    !cart.pick_list.length ||
                    (cart.pick_list.length && cart.pick_list[0].selectedKeyItem !== option.key),
                  'bg-mini-game-table-item-active is-active':
                    cart.pick_list.length && cart.pick_list[0].selectedKeyItem === option.key,
                  '!h-16': data.listOption.length > 5
                })}
              >
                {option.link ? (
                  <div
                    className={cn('flex flex-col items-center gap-1 justify-center rounded-full', {
                      'w-[30px] h-[30px] !p-0 !mt-5': data.listOption.length > 5
                    })}
                    style={{
                      backgroundColor: option.color
                    }}
                  >
                    <img src={option.link} alt='' className='w-[44px] h-3' />
                    <span className='font-bold line-clamp-1 whitespace-nowrap text-mini-game-table-3 text-14 group-[.is-active]:text-white duration-150'>
                      {option.text}
                    </span>
                  </div>
                ) : // <div
                //   className={cn('size-10 p-2 mt-4 flex items-center justify-center rounded-full', {
                //     '!size-[30px] !p-0': data.listOption.length > 5
                //   })}
                //   style={{
                //     backgroundColor: option.color
                //   }}
                // >
                //   <span className='text-12 font-bold '>{option.text}</span>
                // </div>
                ['대', '중', '소'].includes(option?.text) ? (
                  <div className='w-full h-full relative flex items-center justify-center'>
                    <span className='fline-clamp-1 whitespace-nowrap text-mini-game-table-3 text-14 font-bold'>
                      {option.text}
                    </span>
                    <div className='absolute bottom-[-3px] left-0 size-5'>
                      <img src={koreanImages[option?.text]} alt={option?.text} />
                    </div>
                  </div>
                ) : (
                  <img src={koreanImages[option?.text]} alt='' className='size-10 mx-auto' />
                )}
              </span>
              <div className='text-12 absolute w-full flex items-center justify-center h-8 bg-mini-game-table-3 top-0 group-[.is-active]:-z-10'>
                {option.rate?.toFixed(2)}
              </div>
            </button>
          )
        })}
      </div>
    </div>
  )
}

export const BoardBetMiniGameBoscorePowerBall = ({ data, loading = false, handleRefetchGame }: Props) => {
  const [countdown, setCountdown] = useState<number | null>(
    data.end_time - data.now > 0 ? data.end_time - data.now : null
  )
  const timerServer = useMemo(
    () => ({
      startTime: data.start_time,
      endTime: data.end_time,
      now: data.now
    }),
    [data]
  )
  const [isLockBet, setIsLockBet] = useState(data.end_time - data.now < 0)
  const dispatch = useDispatch()
  // const fakeData: OptionMiniGameItem[] = useMemo(
  //   () =>
  //     data.data.map((item) => {
  //       const random = Math.random() < 0.5 ? 'tab1' : 'tab2'
  //       return {
  //         ...item,
  //         tab: random,
  //         tab_text: random
  //       }
  //     }),
  //   []
  // )
  const [tabActive, setTabActive] = useState(data.data[0].tab_text)

  const groupByTabs = groupDataOptionsByTab(data.data)
  const groupCategoryByTab: { [key: string]: Map<string, OptionMiniGameItem[]> | null } = {}
  const groupCategoryByTabObj: {
    [key: string]: {
      key: string
      value: OptionMiniGameItem[]
    }[]
  } = {}
  let groupByCategory = null
  let isTabsExist = false
  let groupByTabsArr: {
    key: string
    value: OptionMiniGameItem[]
  }[] = []
  if (groupByTabs !== null) {
    isTabsExist = true
    groupByTabsArr = convertMapToArrayMiniGame(groupByTabs)
    groupByTabsArr.forEach((tabs) => {
      groupCategoryByTab[tabs.key] = groupDataOptionsByCategory(tabs.value)
      if (groupCategoryByTab[tabs.key] !== null) {
        groupCategoryByTabObj[tabs.key] = convertMapToArrayMiniGame(groupCategoryByTab[tabs.key]!)
      }
    })
    // console.log(groupCategoryByTabObj)
  } else {
    groupByCategory = groupDataOptionsByCategory(data.data)
  }

  useEffect(() => {
    if (timerServer.endTime && countdown && countdown <= data.blocking_time) {
      setIsLockBet(true)
      dispatch(removeAllCartMiniGame())
    }
    if (countdown && countdown < 0) {
      setCountdown(null)
    }
  }, [countdown, timerServer])

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prev) => {
        if (prev !== null) {
          return prev - 1
        }

        return prev
      })
    }, 1000)
    return () => clearInterval(interval)
  }, [data])
  useEffect(() => {
    setCountdown(data.end_time - data.now)
    if (data.end_time - data.now > data.blocking_time) {
      setIsLockBet(false)
    }
    handleRefetchGame()
  }, [data])
  useEffect(() => {}, [tabActive])

  if (groupByCategory === null && isTabsExist === false) {
    // cut'
    return
  }
  const groupByCategoryArr = isTabsExist
    ? groupCategoryByTabObj[tabActive!]
    : groupByCategory !== null
      ? convertMapToArrayMiniGame(groupByCategory)
      : []

  return (
    <div className='flex flex-col gap-1 relative h-full'>
      {isLockBet && (
        <div className='absolute w-full h-full z-10 bg-black/60 flex items-center justify-center'>
          <img src='/assets/images/recharge/block-icon.png' alt='block' title='block' width='50' height='50' />
        </div>
      )}
      <div className='grid grid-cols-2 gap-1 h-[50px] py-1 px-2'>
        <div className='grid grid-cols-[5fr_8fr]'>
          <span className='cols-span-5 bg-mini-game-table-3 flex items-center justify-center text-14 text-mini-game-table-1 text-nowrap'>
            회차
          </span>
          <span className='cols-span-8 bg-mini-game-table-4 flex items-center justify-center text-white font-bold'>
            {data?.round_id?.split('_').length > 1 ? data?.round_id?.split('_')[1] : data?.round_id}
          </span>
        </div>
        <div className='grid grid-cols-[5fr_8fr]'>
          <span className='cols-span-5 bg-mini-game-table-3 flex items-center justify-center text-14 text-mini-game-table-1 text-nowrap'>
            배팅마감
          </span>
          <span className='cols-span-8 bg-mini-game-table-4 flex items-center justify-center text-white font-bold'>
            {loading ? '--:--' : countdown ? formatTimeFromSeconds(countdown) : '--:--'}
          </span>
        </div>
      </div>
      <div className='w-full h-px bg-mini-game-table-4'></div>
      {isTabsExist && groupByTabsArr.length > 1 && (
        <>
          <div className='grid grid-cols-2 gap-8 h-24 py-1 px-5'>
            {groupByTabsArr.map((item) => {
              return (
                <button
                  key={item.key}
                  className={cn(
                    'cols-span-4 bg-mini-game-table-3 flex items-center justify-center text-14 text-mini-game-table-1 rounded-xl border',
                    {
                      'border-mini-game-table-item': tabActive === item.key
                    }
                  )}
                  onClick={() => {
                    setTabActive(item.key)
                  }}
                >
                  {item.key}
                </button>
              )
            })}
            {/* <button
              className={cn(
                'cols-span-4 bg-mini-game-table-3 flex items-center justify-center text-14 text-mini-game-table-1 rounded-xl border',
                {
                  'border-mini-game-table-item': tabActive === 'tab1'
                }
              )}
              onClick={() => {
                setTabActive('tab1')
              }}
            >
              회차
            </button>
            <button
              className={cn(
                'cols-span-4 bg-mini-game-table-3 flex items-center justify-center text-14 text-mini-game-table-1 rounded-xl border',
                {
                  'border-mini-game-table-item': tabActive === 'tab2'
                }
              )}
              onClick={() => {
                setTabActive('tab2')
              }}
            >
              회차
            </button> */}
          </div>
          <div className='w-full h-px bg-mini-game-table-4'></div>
        </>
      )}
      <div className='flex flex-col gap-2'>
        {groupByCategoryArr.map((category, index) => {
          if (index === groupByCategoryArr.length - 1) {
            return (
              <BetItemBoscorePowerBall
                isLock={isLockBet}
                transId={data?.round_id}
                key={index}
                listOption={category.value}
                title={category.key}
              />
            )
          }
          return (
            <React.Fragment key={index}>
              <BetItemBoscorePowerBall
                isLock={isLockBet}
                transId={data?.round_id}
                key={index}
                listOption={category.value}
                title={category.key}
              />
              <div className='w-full h-px bg-mini-game-table-4'></div>
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}
