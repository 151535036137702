import { useQuery } from '@apollo/client'
import { GET_LOGO_PRIORITY_QUERY } from 'src/libs/apis/graphql/queries/page.query'

export default function Loading() {
  const { data: configMeta } = useQuery(GET_LOGO_PRIORITY_QUERY, { context: { apiName: 'auth' } })
  const siLogo2 = configMeta?.PageSiteQuery?.siLogo2
  return (
    <div className='fixed bg-[rgba(0,0,0,0.6)] w-full h-full z-20 top-0 left-0 right-0 bottom-0'>
      <div className='absolute top-[calc(50%-58px)] left-[45%] md:left-[calc(50%-58px)] lg:w-[116px] lg:h-[116px] h-10 w-10'>
        <img
          src={siLogo2}
          onError={(e) => {
            e.currentTarget.src = '/logo.png'
            e.currentTarget.onerror = null
          }}
          alt=''
          className='w-full animate-zoom-loading'
        />
        {/* <div className={`${styles.loader} ${styles.loader_orange}`}></div>
        <div className={`${styles.loader} ${styles.loader_yellow}`}></div> */}
      </div>
    </div>
  )
}
