/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

export default function AvailableCoupon() {
  return (
    <div className='battle__type mt-5 md:mt-0'>
      <div className='flex'>
        <div className='px-2'>
          <div className='mb-[16px]'>낙첨포인트</div>
          <div className='text-center p-[16px] px-6 rounded-md bg-gray-900'>
            <div>
              <div>금주 예상 낙첨 포인트</div>
              <div className='rounded-md p-[16px] mt-[16px] mb-[16px] text-yellow-500 bg-gray-800'>0 원</div>
              <div>지난주 낙첨 포인트</div>
              <div className='rounded-md p-[16px] mt-[16px] mb-[16px] text-yellow-500 bg-gray-800'>0 원</div>
              <div className='text-xs mt-8 mb-[16px]'>*누적금액 및 누적 포인트는 00시마다 업데이트됩니다.</div>
            </div>
          </div>
        </div>
        <div className='px-2'>
          <div className='mb-[16px]'>낙첨포인트</div>
          <div className='text-center p-[16px] px-6 rounded-md bg-gray-900'>
            <div>
              <div>금주 예상 낙첨 포인트</div>
              <div className='rounded-md p-[16px] mt-[16px] mb-[16px] text-yellow-500 bg-gray-800'>0 원</div>
              <div>지난주 낙첨 포인트</div>
              <div className='rounded-md p-[16px] mt-[16px] mb-[16px] text-yellow-500 bg-gray-800'>0 원</div>
              <div className='text-xs mt-8 mb-[16px]'>*누적금액 및 누적 포인트는 00시마다 업데이트됩니다.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
