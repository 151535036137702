import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import ReChargeWithDrawInfo from 'src/components/ReChargeWithDrawInfo'
import DataTable from 'src/components/Table/DataTable'
import WithDrawForm from 'src/components/WithDrawForm'
import Button from 'src/components/ui/Button'
import { DELETE_MONEY_INFO_BY_PERIOD_MUTATION } from 'src/libs/apis/graphql/mutations/money.mutation'
import { MoneyInfoListQuery } from 'src/libs/apis/graphql/queries/money.query'
import { SiteInfoQuery } from 'src/libs/apis/graphql/queries/siteInfo.query'
import { WITHDRAW_CHECK_QUERY } from 'src/libs/apis/graphql/queries/withdraw.query'
import { showErrorToast } from 'src/libs/utils/error'
import { RootState } from 'src/libs/utils/store'
import { formatCurrency } from 'src/libs/utils/utilFuncs'
import { Wallet } from 'src/types/wallet.type'
import { getWithdrawColumns } from './withdrawColumns'

export type DataMoneyType = {
  mProcessDate?: string
  miBankMoney?: number
  miNo?: number
  miRegDate?: string
  miStatus?: string
  miType?: string
  from?: string
  to?: string
  __typename?: string
}

export type DataWithDrawCheckType = {
  WithdrawCheckQuery: {
    sports_current_money: number
    sports_withdraw_money: number
    casino_current_money: number
    casino_withdraw_money: number
    is_enable_sports_casino_withdraw: boolean
    sum_current_money: number
  }
}

export const MIN_WITHDRAW_DEFAULT_VALUE = 10_000
export const MAX_WITHDRAW_VALUE = 2_000_000_000
const WITHDRAW_TYPE = 'withdraw'

export const SPORT_WALLET = 'sports'
export const CASINO_SLOT_WALLET = 'casino_slot'

const TYPE_OF_PROJECT: 'casino_slot_only' | 'casino_slot_sports' = 'casino_slot_only'

export default function WithDraw() {
  const dataInfo = useQuery(SiteInfoQuery, {
    context: { apiName: 'siteInfo' },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })
  const [dataRecharge, setDataRecharge] = useState([])
  const [pagination, setPagination] = useState({ current: 1, pageSize: 5, total: 0 })
  const user = useSelector((state: RootState) => state.auth.user)
  const { mBankOwner = '', mBankNumber = 0 } = user || {}
  const dispatch = useDispatch()
  const [wallet, setWallet] = useState<Wallet>(TYPE_OF_PROJECT === 'casino_slot_only' ? 'casino_slot' : 'sports')

  const chooseWallet = (wallet: Wallet) => {
    setWallet(wallet)
  }

  const { data, refetch, loading } = useQuery(MoneyInfoListQuery, {
    variables: { limit: pagination.pageSize || 1, page: pagination.current || 1, type: WITHDRAW_TYPE },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    context: { apiName: 'money-info' }
  })

  const { data: dataWithdrawCheckQuery } = useQuery<DataWithDrawCheckType>(WITHDRAW_CHECK_QUERY, {
    context: { apiName: 'money-info' },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  const [deleteMoney] = useMutation(DELETE_MONEY_INFO_BY_PERIOD_MUTATION, {
    context: { apiName: 'money-info' },
    variables: { type: WITHDRAW_TYPE, days: 3 },
    onCompleted: (data) => {
      if (!data.DeleteByDays) {
        toast.error('삭제에 실패하였습니다')
        return
      }

      refetch()
      toast.success('삭제되었습니다')
    },
    onError: (error) => {
      showErrorToast(error)
    }
  })

  useEffect(() => {
    if (data && data.MoneyInfoListQuery && data.MoneyInfoListQuery?.data) {
      setDataRecharge(
        data.MoneyInfoListQuery.data.map((item: DataMoneyType, index: number) => ({
          ...item,
          key: index
        }))
      )
    }
  }, [data, mBankNumber, mBankOwner])

  useEffect(() => {
    if (data && data?.MoneyInfoListQuery && data?.MoneyInfoListQuery?.total) {
      setPagination({
        ...pagination,
        total: data.MoneyInfoListQuery.total
      })
    }
  }, [data])

  // useEffect(() => {
  //   if (loading) {
  //     dispatch(setIsLoadingPage(true))
  //   }

  //   return () => {
  //     dispatch(setIsLoadingPage(false))
  //   }
  // }, [dispatch, loading])

  const getProgressValue = () => {
    const {
      sports_current_money,
      sports_withdraw_money,
      casino_current_money,
      casino_withdraw_money,
      sum_current_money
    } = dataWithdrawCheckQuery?.WithdrawCheckQuery || {
      sports_current_money: 0,
      sports_withdraw_money: 1,
      casino_current_money: 0,
      casino_withdraw_money: 1,
      sum_current_money: 0
    }

    const currentMoney = sum_current_money || 0
    const targetMoney = (wallet === SPORT_WALLET ? sports_withdraw_money : casino_withdraw_money) || 1

    return (currentMoney / targetMoney) * 100 || 0
  }

  const renderProgressByWalletWithMoney = () => {
    const { sports_current_money, casino_current_money } = dataWithdrawCheckQuery?.WithdrawCheckQuery || {
      sports_current_money: 0,
      casino_current_money: 0
    }

    const currentMoneyByWallet = {
      sports: sports_current_money || 0,
      casino_slot: casino_current_money || 0
    }

    const rollingImage = {
      sports: '/assets/images/recharge/bonus.png',
      casino_slot: '/assets/images/money/casino.png'
    }

    const totalRate = getProgressValue()

    return (
      <>
        <div className='relative h-8 rounded overflow-hidden'>
          <progress className='progress progress3 w-full h-full' max='100' value={totalRate}></progress>
          <p className='absolute top-1/2 left-2 -translate-y-1/2 z-[1] text-14 text-white font-semibold'>
            {Number(totalRate.toFixed(2)) !== totalRate ? totalRate.toFixed(2) + '%' : totalRate + '%'}
          </p>
        </div>

        <div className='flex items-center mt-4 gap-[100px]'>
          <div>
            <div className={`w-20 h-20 border-2 border-primary flex justify-center items-center`}>
              <img src={rollingImage[wallet]} alt='bonus' title='bonus' width='50' height='50' />
            </div>
            <p className='font-semibold text-14 mt-2 p-1 bg-black text-primary rounded text-center'>
              {formatCurrency(currentMoneyByWallet[wallet])} 원
            </p>
          </div>
          <div>
            <div className={`w-20 h-20 border-2 border-primary flex justify-center items-center`}>
              <img src={rollingImage[SPORT_WALLET]} alt='bonus' title='bonus' width='50' height='50' />
            </div>
            <p className='font-semibold text-14 mt-2 p-1 bg-black text-primary rounded text-center'>
              {formatCurrency(currentMoneyByWallet[SPORT_WALLET])} 원
            </p>
          </div>
        </div>
      </>
    )
  }

  const renderWalletList = () => {
    if (TYPE_OF_PROJECT === 'casino_slot_only') return

    return (
      <div className='w-full mt-6 border border-[#444444] pt-0 py-3'>
        <div className='px-2 flex w-full gap-2 mt-5 justify-between flex-wrap'>
          <Button
            className={`flex flex-auto justify-between rounded p-3 items-center text-12 border border-primary bg-black ${wallet === 'sports' ? 'bg-primary text-black' : ''}`}
            type='button'
            onClick={() => {
              chooseWallet('sports')
            }}
          >
            <span>스포츠캐쉬</span>
            <span>{formatCurrency(user?.mSportsMoney)} 원</span>
          </Button>
          <Button
            className={`flex flex-auto justify-between rounded p-3 items-center text-12 border border-primary bg-black ${wallet === 'casino_slot' ? 'bg-primary text-black' : ''}`}
            type='button'
            onClick={() => {
              chooseWallet('casino_slot')
            }}
          >
            <span>캐쉬</span>
            <span>{formatCurrency(user?.mMoney)} 원</span>
          </Button>
        </div>
      </div>
    )
  }

  return (
    <section className='mt-2 md:p-4' data-aos='fade-up'>
      <div className='p-2.5 border border-[#2f2f2f]'>
        <div className='flex flex-col justify-between gap-[15px]'>
          <ReChargeWithDrawInfo
            title={dataInfo?.data?.SiteInfoQuery?.withdraw_config?.wc_rules}
            titleButton='출금규정 확인하기'
            titleInfo='주의사항/안내사항'
          />
          {renderWalletList()}
          <div className='w-full'>
            <div className='border border-[#444444] '>
              <div className='pb-5 w-full p-2'>
                <div className=''>
                  <div className='flex gap-2 items-center rounded border-b border-[#444444] h-10 px-2'>
                    <h3 className='font-bold text-[#ffffff] text-14'>롤링현황</h3>
                  </div>
                </div>
                {renderProgressByWalletWithMoney()}
              </div>
            </div>

            <div className='w-full pb-5 mt-6'>
              <div className='mt-5'>
                <WithDrawForm dataInfo={dataInfo?.data} wallet={wallet} />
              </div>
            </div>
          </div>
        </div>
        <div className='flex justify-between items-center mt-[30px] mb-3'>
          <h2 className='text-white text-22 font-bold'>출금내역</h2>
          <Button
            className='h-8 rounded bg-[#841111] w-fit px-4 min-w-20 font-medium text-14 flex justify-center items-center gap-2'
            type='button'
            onClick={() => deleteMoney()}
          >
            <img src='/assets/images/icons/icon-delete.svg' alt='Delete' title='Delete' width='20' height='25' />
            3일전 내역 삭제
          </Button>
        </div>
        <div className='table-responsive'>
          <DataTable
            columns={getWithdrawColumns()}
            loading={loading}
            dataSource={dataRecharge}
            pagination={{
              position: ['bottomCenter'],
              total: pagination.total,
              current: pagination.current,
              pageSize: pagination.pageSize,
              onChange: (page, pageSize) => {
                if (loading) return
                setPagination({ ...pagination, current: page, pageSize })
                refetch()
              }
            }}
          />
        </div>
      </div>
    </section>
  )
}
