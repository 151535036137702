import { Table, TableProps } from 'antd'
import './index.scss'
import { useViewport } from 'src/libs/hooks/useWindowDimension'

type Props = TableProps

const DataTable = (props: Props) => {
  const { className, ...rest } = props
  const { isSP } = useViewport()

  return (
    <Table
      rootClassName='text-nowrap'
      pagination={false}
      scroll={{ x: isSP ? 900 : 400 }}
      {...rest}
      className={`data-table ${className}`}
    />
  )
}

export default DataTable
