/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useQuery } from '@apollo/client'
import cn from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { GET_MENU_MINI_GAME } from 'src/libs/apis/graphql/queries/mini_game.query'
import { GET_PARSING_CASINO_MENU } from 'src/libs/apis/graphql/queries/parsing-casino.query'
import { MenuMiniGameList, ParsingCasinoList } from 'src/types/minigame.type'
import './index.scss'

export interface Menu {
  no?: number
  name?: string
  nameEn?: string
  path?: string
  link?: string
  logo?: string
  subMenu: {
    status?: string
    no?: number
    name?: string
    nameEn?: string
    path?: string
    link?: string
    logo?: string
  }[]
}

interface DesktopMenuProps {
  menu: Menu[]
  miniGameActive: string
  setMiniGameActive: React.Dispatch<React.SetStateAction<string>>
  navigate: (path: string, options?: { state: { iframeLink: string } }) => void
}

interface MobileMenuProps {
  menu: Menu[]
  miniGameActive: string
  setMiniGameActive: React.Dispatch<React.SetStateAction<string>>
  isShowMenu: boolean
  setIsShowMenu: React.Dispatch<React.SetStateAction<boolean>>
  subMenu: Menu['subMenu']
  navigate: (path: string) => void
}

interface SubMenuProps {
  subMenu: Menu['subMenu']
  tabActive: number
  setTabActive: React.Dispatch<React.SetStateAction<number>>
  navigate: (path: string, options?: { state: { iframeLink: string } }) => void
  sliderRef: React.RefObject<HTMLDivElement>
  handleMouseDown: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  handleMouseLeave: () => void
  handleMouseUp: () => void
  handleMouseMove: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const MiniGame = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [tabActive, setTabActive] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const sliderRef = useRef<HTMLDivElement>(null)
  const [miniGameActive, setMiniGameActive] = useState('')
  const [menu, setMenu] = useState<Menu[]>([])
  const [isShowMenu, setIsShowMenu] = useState(false)

  const { data: miniGameMenu } = useQuery<MenuMiniGameList>(GET_MENU_MINI_GAME, {
    context: { apiName: 'mini-game' }
  })

  const { data: parsingCasinoMenu } = useQuery<ParsingCasinoList>(GET_PARSING_CASINO_MENU, {
    context: { apiName: 'parsing-casino' }
  })

  useEffect(() => {
    if (miniGameMenu?.MenuMiniGameQuery?.length || parsingCasinoMenu?.MenuParsingCasinoQuery?.length) {
      const parseGameMenu = miniGameMenu?.MenuMiniGameQuery?.map((item) => {
        if (!item.MiniGames.length) {
          return null
        }
        return {
          no: item.mgpNo,
          name: item.mgpName,
          nameEn: item.mgpNameEn,
          path: item.MiniGames[0].mgPath,
          link: item.MiniGames[0].mgFrameLink,
          logo: item.mgpLogo,
          key: item.mgpNo,
          subMenu: item.MiniGames.map((subItem) => ({
            no: subItem.mgNo,
            name: subItem.mgName,
            nameEn: subItem.mgNameEn,
            path: subItem.mgPath,
            link: subItem.mgFrameLink,
            logo: item.mgpLogo
          }))
        }
      }).filter((i) => i !== null)

      const parseCasinoMenu = parsingCasinoMenu?.MenuParsingCasinoQuery?.map((item) => {
        if (!item.Providers.length) {
          return null
        }
        return {
          no: item.pcpNo,
          name: item.pcpName,
          nameEn: item.pcpNameEn,
          path: item.Providers[0]?.pcPath?.replace('/mini/parsing-casino/', '/mini/'),
          link: item.Providers[0]?.pcFrameLink,
          logo: item.pcpLogo,
          key: item.pcpNo,
          subMenu: item.Providers.map((subItem) => ({
            no: subItem.pcNo,
            name: subItem.pcName,
            nameEn: subItem.pcNameEn,
            path: (subItem.pcPath || '').replace('/mini/parsing-casino/', '/mini/'),
            link: subItem.pcFrameLink,
            logo: item?.pcpLogo || '/default-minigame.png'
          }))
        }
      }).filter((i) => i !== null)

      setMenu([...(parseGameMenu || []), ...(parseCasinoMenu || [])])
    }
  }, [miniGameMenu, parsingCasinoMenu])

  useEffect(() => {
    if (location.pathname && menu?.length) {
      const foundMenu = menu.find((item) => item.subMenu.some((subItem) => subItem.path === location.pathname))
      if (foundMenu) {
        setMiniGameActive(foundMenu?.name || '')
        const tabActiveIndex = foundMenu.subMenu.findIndex((item) => item.path === location.pathname)
        setTabActive(tabActiveIndex !== -1 ? tabActiveIndex : 0)
      }
    }
  }, [location.pathname, menu])

  const subMenu = useMemo(() => {
    if (miniGameActive) {
      const foundMenu = menu.find((item) => item.name === miniGameActive)
      return foundMenu?.subMenu || []
    }
    return menu[0]?.subMenu || []
  }, [miniGameActive, menu])

  const handleMouseDown = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setIsDragging(true)
    if (sliderRef.current) {
      setStartX(e.pageX - sliderRef.current.offsetLeft)
      setScrollLeft(sliderRef.current.scrollLeft)
    }
  }

  const handleMouseMove = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!isDragging || !sliderRef.current) return
    e.preventDefault()
    const x = e.pageX - sliderRef.current.offsetLeft
    const walk = (x - startX) * 1.2 // tốc độ kéo
    sliderRef.current.scrollLeft = scrollLeft - walk
  }

  const handleMouseLeave = () => setIsDragging(false)
  const handleMouseUp = () => setIsDragging(false)

  return (
    <div className='flex flex-col overflow-hidden relative'>
      <DesktopMenu
        menu={menu}
        miniGameActive={miniGameActive}
        setMiniGameActive={setMiniGameActive}
        navigate={navigate}
      />
      <MobileMenu
        menu={menu}
        miniGameActive={miniGameActive}
        setMiniGameActive={setMiniGameActive}
        isShowMenu={isShowMenu}
        setIsShowMenu={setIsShowMenu}
        subMenu={subMenu}
        navigate={navigate}
      />
      <SubMenu
        subMenu={subMenu}
        tabActive={tabActive}
        setTabActive={setTabActive}
        navigate={navigate}
        sliderRef={sliderRef}
        handleMouseDown={handleMouseDown}
        handleMouseLeave={handleMouseLeave}
        handleMouseUp={handleMouseUp}
        handleMouseMove={handleMouseMove}
      />
      <Outlet context={{ menu }} />
    </div>
  )
}

const DesktopMenu: React.FC<DesktopMenuProps> = ({ menu, miniGameActive, setMiniGameActive, navigate }) => (
  <div className='max-w-full scrollbar-hide overflow-y-auto hidden md-up:block'>
    <div className='flex items-center'>
      {menu.map((item, index) => (
        <div key={index} className='flex-1 flex flex-col'>
          <button
            onClick={() => {
              navigate(item.path || '', { state: { iframeLink: item.link || '' } })
              setMiniGameActive(item.name || '')
            }}
            className={cn('h-11 text-12 md:text-16 min-w-[100px] flex justify-center items-center', {
              'border border-primary !border-b-0 text-primary': miniGameActive === item.name
            })}
          >
            <p className='flex items-center gap-2'>
              <img
                src={item.logo || '/default-minigame.png'}
                alt={item.name}
                className='w-25 h-5'
                onError={(e) => {
                  e.currentTarget.src = '/default-minigame.png'
                  e.currentTarget.onerror = null
                }}
              />
              {item.name}
            </p>
          </button>
        </div>
      ))}
    </div>
  </div>
)

const MobileMenu: React.FC<MobileMenuProps> = ({
  menu,
  miniGameActive,
  setMiniGameActive,
  isShowMenu,
  setIsShowMenu,
  subMenu,
  navigate
}) => (
  <>
    <div className='flex flex-col gap-1 md-up:hidden'>
      {[0, 3, 5].map((start, index) => (
        <div key={index} className={`grid grid-cols-${index === 0 ? 3 : 2}`}>
          {menu.slice(start, start + (index === 0 ? 3 : 2)).map((item) => (
            <button
              onClick={() => {
                setMiniGameActive(item.name || '')
                setIsShowMenu(true)
                document.getElementById('main-content')?.classList.add('open-modal')
              }}
              key={item.name}
              className={cn(
                'flex items-center justify-center gap-2 hover:bg-primary duration-150 hover:text-black py-2',
                {
                  'bg-primary text-black': miniGameActive === item.name,
                  'bg-secondary-2 text-white': miniGameActive !== item.name
                }
              )}
            >
              <img
                src={item.logo || '/default-minigame.png'}
                alt={item.name}
                className='size-5'
                onError={(e) => {
                  e.currentTarget.src = '/default-minigame.png'
                  e.currentTarget.onerror = null
                }}
              />
              <span>{item.name}</span>
            </button>
          ))}
        </div>
      ))}
    </div>
    {isShowMenu && (
      <div className='absolute md-up:!hidden w-full h-screen top-0 left-0 bg-black/50 z-[9999]'>
        <div className='py-5'>
          <div className='flex flex-col gap-6 px-4'>
            <div className='self-end'>
              <button
                onClick={() => {
                  setIsShowMenu(false)
                  document.getElementById('main-content')?.classList.remove('open-modal')
                }}
              >
                <img src='/Close.png' alt='close icon' className='cursor-pointer' />
              </button>
            </div>
            <div className='max-h-[calc(100vh_-150px-100px)] overflow-y-auto scrollbar-hide'>
              <div className='grid grid-cols-2 gap-6 w-full'>
                {subMenu.map((item, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      document.getElementById('main-content')?.classList.remove('open-modal')
                      navigate(item.path || '')
                      setIsShowMenu(false)
                    }}
                    className='col-span-1 py-8 min-w-full bg-black/85'
                  >
                    <div className='flex flex-col md:flex-row items-center justify-center gap-3'>
                      <img
                        src={item.logo || '/default-minigame.png'}
                        alt={item.name}
                        className='w-[28px] h-[28px] object-contain'
                        onError={(e) => {
                          e.currentTarget.src = '/default-minigame.png'
                          e.currentTarget.onerror = null
                        }}
                      />
                      <span className='text-[14px] font-[700]'>{item.name}</span>
                      <span className='text-[14px] font-[700]'>On</span>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </>
)

const SubMenu: React.FC<SubMenuProps> = ({
  subMenu,
  tabActive,
  setTabActive,
  navigate,
  sliderRef,
  handleMouseDown,
  handleMouseLeave,
  handleMouseUp,
  handleMouseMove
}) => (
  <div className='max-w-full overflow-auto scrollbar-hide border border-gray-2 hidden md-up:block'>
    <nav
      ref={sliderRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      className='flex items-center md:overflow-hidden'
    >
      {subMenu.map((item, index) => (
        <button
          key={index}
          onClick={() => {
            setTabActive(index)
            navigate(item.path || '', { state: { iframeLink: item.link || '' } })
          }}
          className={cn(
            `flex-1 text-12 md:text-14 cursor-pointer bg-black border border-primary px-2 md:px-4 py-2 min-w-[150px] 2xl:min-w-[0px] text-center transition-all line-clamp-1 whitespace-nowrap`,
            {
              'text-primary font-bold': tabActive === index
            }
          )}
        >
          {item.name}
        </button>
      ))}
    </nav>
  </div>
)

export default MiniGame
