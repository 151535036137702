import { gql } from '@apollo/client'

export const WITHDRAW_CHECK_QUERY = gql`
  query WithdrawCheckQuery {
    WithdrawCheckQuery {
      sports_current_money
      sports_withdraw_money
      casino_current_money
      casino_withdraw_money
      is_enable_sports_casino_withdraw
      sum_current_money
    }
  }
`
