import { useMutation, useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangeEvent } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import { WithdrawMutation } from 'src/libs/apis/graphql/mutations/money.mutation'
import { SiteInfoQuery } from 'src/libs/apis/graphql/queries/siteInfo.query'
import { WITHDRAW_CHECK_QUERY } from 'src/libs/apis/graphql/queries/withdraw.query'
import { showErrorToast } from 'src/libs/utils/error'
import { withDrawSchema } from 'src/libs/utils/rules'
import { RootState } from 'src/libs/utils/store'
import { formatCurrency, reFormatCurrency } from 'src/libs/utils/utilFuncs'
import { CASINO_SLOT_WALLET, DataWithDrawCheckType, SPORT_WALLET } from 'src/pages/WithDraw/WithDraw'
import { SiteInfoType } from 'src/types/common.type'
import Input from '../Input'
import Button from '../ui/Button'
import { dataButton } from './dataButton'

type WithDrawForm = {
  amount: string
}

type WithDrawFormProps = {
  dataInfo: SiteInfoType
  wallet: string
}

export const getValidValue = (value: string | number | undefined) => {
  if (!value) return

  return Math.floor(Number(value) / 10_000) * 10_000
}

export default function WithDrawForm({ dataInfo, wallet }: WithDrawFormProps) {
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<WithDrawForm>({
    mode: 'onBlur',
    resolver: yupResolver(withDrawSchema(dataInfo))
  })

  const { mMoney, mSportsMoney } = useSelector((state: RootState) => state.auth.user) || {}

  const { data: dataWithdrawCheckQuery, refetch: refetchWithdrawCheckQuery } = useQuery<DataWithDrawCheckType>(
    WITHDRAW_CHECK_QUERY,
    {
      context: { apiName: 'money-info' },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  )

  const [withdraw, { loading }] = useMutation(WithdrawMutation, {
    onCompleted: async () => {
      refetchWithdrawCheckQuery()
      toast.success('출금신청되었습니다')
    },
    onError: (error) => {
      showErrorToast(error)
    }
  })

  const refreshInput = () => {
    setError('amount', {})
    setValue('amount', '')
  }
  const onSubmit = handleSubmit(async (formData: WithDrawForm) => {
    try {
      await withdraw({
        variables: {
          amount: reFormatCurrency(formData.amount),
          wallet
        },
        context: { apiName: 'money-info' }
      })
    } catch (error) {
      refreshInput()
      return
    }

    refreshInput()
  })

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const numericValue = value.replace(/\D/g, '')
    event.target.value = formatCurrency(numericValue)
  }

  const handleButtonClick = (value: number) => {
    const currentValue = watch('amount')
    const newValue = parseInt(currentValue.replace(/[^\d]/g, '')) || 0
    setValue('amount', formatCurrency((newValue + value).toString()))
  }

  const { sports_current_money, sports_withdraw_money, casino_current_money, casino_withdraw_money } =
    dataWithdrawCheckQuery?.WithdrawCheckQuery || {}

  const is_force_enable_all = dataWithdrawCheckQuery?.WithdrawCheckQuery?.is_enable_sports_casino_withdraw
  const is_enable_sports = Number(sports_current_money) >= Number(sports_withdraw_money) || is_force_enable_all
  const is_enable_casino = Number(casino_current_money) >= Number(casino_withdraw_money) || is_force_enable_all
  const { data: siteinfo } = useQuery(SiteInfoQuery, {
    context: { apiName: 'siteInfo' },
    fetchPolicy: 'no-cache'
  })

  return (
    <form noValidate onSubmit={onSubmit} className='relative py-4'>
      {wallet === SPORT_WALLET && !is_enable_sports && (
        <div className='absolute w-full h-full z-[1] bg-[rgba(0,0,0,0.6)] flex justify-center items-center flex-col gap-2'>
          <img src='/assets/images/recharge/block-icon.png' alt='block' title='block' width='50' height='50' />
          <p>롤링 완료 후 출금가능합니다.</p>
        </div>
      )}
      {wallet === CASINO_SLOT_WALLET && !is_enable_casino && (
        <div className='absolute w-full h-full z-[1] bg-[rgba(0,0,0,0.6)] flex justify-center items-center flex-col gap-2'>
          <img src='/assets/images/recharge/block-icon.png' alt='block' title='block' width='50' height='50' />
          <p>롤링 완료 후 출금가능합니다.</p>
        </div>
      )}
      <div className='border border-[#444444]'>
        <div className='border-b border-[#444444]'>
          <div className='flex'>
            <p className='min-w-[100px] md:min-w-[130px] flex justify-center items-center bg-[#333333]'>출금하실금액</p>
            <div className='flex flex-wrap gap-2 border-b-[1px] border-[#32230c] ml-1 md:ml-3 mt-2 md:mt-0 pb-2 md:py-2.5'>
              <Input
                name='amount'
                type='text'
                register={register}
                placeholder='출금(환전) 금액'
                errorMessage={errors?.amount?.message}
                required
                disabled={dataInfo?.SiteInfoQuery?.withdraw_config?.wc_manual_withdraw}
                classNameInput='placeholder:text-[#757575] text-dark text-14 h-10 p-3 rounded border md:max-w-[190px] bg-black !border-[#444444] placeholder:!text-[#766a57]'
                classNameError='text-red-1 mt-1 text-12'
                classNameLabel='min-w-[130px] mt-2'
                onChange={(e) => onInputChange(e)}
              />
              <div className='flex flex-wrap gap-1'>
                {dataButton?.map((item, index) => (
                  <Button
                    className='h-10 bg-black border border-primary text-white md:w-fit px-4 w-[calc(50%-4px)] md:min-w-20 font-medium text-14'
                    type='button'
                    key={index}
                    onClick={() => handleButtonClick(item.value)}
                  >
                    {item?.name}
                  </Button>
                ))}
                <Button
                  className='h-10 bg-black border border-primary text-white md:w-fit px-4 w-[calc(50%-4px)] md:min-w-20 font-medium text-14'
                  type='button'
                  onClick={() =>
                    setValue(
                      'amount',
                      formatCurrency(
                        (wallet === 'sports' ? getValidValue(mSportsMoney) : getValidValue(mMoney))?.toString() ?? ''
                      )
                    )
                  }
                >
                  Max
                </Button>
                <Button
                  className='h-10 bg-black border border-primary text-white md:w-fit px-4 w-[calc(50%-4px)] md:min-w-20 font-medium text-14'
                  type='button'
                  onClick={() => setValue('amount', '')}
                >
                  정정하기
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {(wallet === 'SPORT_WALLET' && !is_enable_sports) || (wallet === 'CASINO_SLOT_WALLET' && !is_enable_casino) ? (
        <>
          {siteinfo?.SiteInfoQuery?.transfer_coin_url && (
            <NavLink
              className='rounded h-11 bg-primary text-white w-fit flex justify-center items-center min-w-[90px] font-medium text-14 mt-3 mr-3'
              to={siteinfo?.SiteInfoQuery?.transfer_coin_url}
              target='_blank'
            >
              코인판매가기
            </NavLink>
          )}
          <div className='rounded h-11 bg-primary text-white w-fit px-4 min-w-[90px] font-medium text-14 mt-3'>
            환전하기
          </div>
        </>
      ) : (
        <div className='flex justify-center items-center'>
          {siteinfo?.SiteInfoQuery?.transfer_coin_url && (
            <NavLink
              className='rounded h-11 bg-primary text-white w-fit flex justify-center items-center min-w-[90px] font-medium text-14 mt-3 mr-3'
              to={siteinfo?.SiteInfoQuery?.transfer_coin_url}
              target='_blank'
            >
              코인판매가기
            </NavLink>
          )}

          <Button
            type='submit'
            disabled={loading}
            className={`rounded h-11 text-white w-fit px-4 min-w-[90px] font-medium text-14 mt-3 ${
              loading ? 'bg-gray-500' : 'bg-primary'
            }`}
          >
            환전하기
          </Button>
        </div>
      )}
    </form>
  )
}
