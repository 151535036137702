import { Link, Outlet, useLocation } from 'react-router-dom'
import path from 'src/constants/path'

export default function MoneyLayout() {
  const location = useLocation()

  const data = [
    { name: '입금', url: path.recharge },
    { name: '출금', url: path.withdraw },
    // { name: '머니이동', url: path.move_money },
    { name: '캐쉬내역', url: path.cashhistory }
  ]
  return (
    <>
      <div className='w-full grid grid-cols-3 mt-2 px-2'>
        {data?.map((item, index) => (
          <Link
            to={item?.url}
            title={item?.name}
            key={index}
            className={`text-center h-8 lg:h-[50px] flex justify-center items-center hover:border-b hover:border-primary hover:text-primary ${location.pathname === item?.url ? 'border-b border-primary text-primary' : ''}`}
          >
            {item?.name}
          </Link>
        ))}
      </div>
      <div>
        <Outlet />
      </div>
    </>
  )
}
