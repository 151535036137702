import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../stores/auth.reducer'
import commonReducer from '../stores/common.reducer'
import liveSportSearchReducer from '../stores/live-sport-search.reducer'
import liveSportReducer from '../stores/live-sport.reducer'
import miniGameReducer from '../stores/minigame.reducer'
import sportSearchReducer from '../stores/sport-search.reducer'
import sportReducer from '../stores/sport.reducer'
import virtualSportReducer from '../stores/virtual-sport.reducer'
import virtualSportSearchReducer from '../stores/virtual-sport-search.reducer'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    common: commonReducer,
    sport: sportReducer,
    sportSearch: sportSearchReducer,
    miniGame: miniGameReducer,
    liveSportSearch: liveSportSearchReducer,
    liveSport: liveSportReducer,
    virtualSport: virtualSportSearchReducer,
    virtual: virtualSportReducer
  }
})

/* 
  Lấy RootState và AppDispatch từ store
*/
export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
