/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import cn from 'classnames'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import path from 'src/constants/path'
import { BET_MUTATION } from 'src/libs/apis/graphql/mutations/virtual-sport.mutation'
import { getMember } from 'src/libs/apis/graphql/queries/auth.query'
import { GET_HISTORY_VIRTUAL_SPORTS, GET_LIMIT_VIRTUAL_SPORTS } from 'src/libs/apis/graphql/queries/virtual-sport.query'
import { useCartVirtualSportsStore } from 'src/libs/hooks/store/useCartVirtualSportsStore'
import { setSportsMoney } from 'src/libs/stores/auth.reducer'
import {
  BetItem,
  removeAllCartVirtualSport as removeAllCartSport,
  removeAllCartVirtualSport,
  removeItemFromCartVirtualSport as removeItemFromCartSport,
  storeLimitVirtualSportBetting
} from 'src/libs/stores/virtual-sport.reducer'
import { client } from 'src/libs/utils/apollo'
import { getTokenDynamic } from 'src/libs/utils/auth'
import { RootState } from 'src/libs/utils/store'
import { formatCurrency } from 'src/libs/utils/utilFuncs'
import { transformCartVirtualSportToPickList } from 'src/libs/utils/virtual-sports.func'
import { isGreyhoundsOrHorse } from 'src/pages/VirtualSports/hooks/useVirtualSports'
import { PaginationType } from 'src/types/common.type'
import { LimitSport } from 'src/types/virtual-sport'
import { BetHistoryGroup, BetHistoryQuery, BetItemHistory, EStatusBetting } from 'src/types/virtual-sport.api'
const SPORT_CART_BET = 1
const SPORT_CART_HISTORY = 2

export default function VirtualSportsCart() {
  const dispatch = useDispatch()
  const category = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]

  const { cart: cartSport, limitBetting } = useCartVirtualSportsStore()
  const user = useSelector((state: RootState) => state.auth.user)

  const [crashBet, setCrashBet] = useState(0)
  const [tab, setTab] = useState(SPORT_CART_BET)
  const [query, setQuery] = useState<PaginationType>({ offset: 0, limit: 10, total: 0 })
  const readTokenFromLS = useSelector((state: RootState) => state.auth.readTokenFromLS)
  const token = useMemo(() => getTokenDynamic(readTokenFromLS), [user])

  const { data: betData, refetch: refetchBetData } = useQuery<BetHistoryQuery>(GET_HISTORY_VIRTUAL_SPORTS, {
    variables: {
      cate: category,
      limit: 3,
      offset: query.offset ?? 0
    },
    context: { apiName: 'virtual-sports' }
  })

  const [getSportsLimit, { data }] = useLazyQuery<LimitSport, { count_bet: number; cate: string }>(
    GET_LIMIT_VIRTUAL_SPORTS,
    {
      context: { apiName: 'virtual-sports' },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        dispatch(storeLimitVirtualSportBetting({ limitSport: data }))
      }
    }
  )
  const location = useLocation()
  const pathGreyhounds = path.virtual_sports_greyhounds
  const pathHorse = path.virtual_sports_horse

  const limit = data?.BettingLimitsVirtualSportQuery || {
    max_bet_amount: 0,
    max_bet_payout: 0,
    max_payout: 0,
    min_bet_amount: 0,
    percent_distribution: 0
  }

  const totalBetRate = useMemo(() => {
    if (cartSport.pick_list.length === 0) return 0
    return cartSport.pick_list.reduce((sum, item) => {
      return sum * Number(item.rate)
    }, 1)
  }, [JSON.stringify(cartSport.pick_list), JSON.stringify(cartSport)])

  const totalBetLimit = Number(totalBetRate.toFixed(2))
  const formattedTotalBetRate = totalBetLimit?.toFixed(2)
  const totalMoney =
    crashBet * Number(formattedTotalBetRate) > (limitBetting?.BettingLimitsVirtualSportQuery?.max_bet_payout || 0)
      ? (limitBetting?.BettingLimitsVirtualSportQuery?.max_bet_payout || 0).toFixed(0)
      : (crashBet * Number(formattedTotalBetRate)).toFixed(0)

  // TODO: refactor use apollo cache will better
  const [refreshMemberInfo] = useLazyQuery(getMember, {
    context: { apiName: 'member' },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      dispatch(setSportsMoney(data?.MemberQuery?.mMoney))
    }
  })

  const [betMutation, { loading }] = useMutation(BET_MUTATION, {
    context: { apiName: 'virtual-sports' },
    variables: {
      bet_cash: crashBet,
      bet_category: location.pathname.split('/')[location.pathname.split('/').length - 1],
      pick_list: cartSport?.pick_list.map((item) => {
        return transformCartVirtualSportToPickList(item)
      })
    },
    onCompleted: async (data) => {
      if (data?.VirtualSportBetMutation?.status === true) {
        toast.success(data?.VirtualSportBetMutation?.message)
        dispatch(removeAllCartVirtualSport())
        setCrashBet(0)
        await refreshMemberInfo()
        await client.refetchQueries({
          include: [GET_HISTORY_VIRTUAL_SPORTS]
        })
      } else {
        toast.error(data?.VirtualSportBetMutation?.message)
      }
    },
    onError: (error) => {
      toast.error(error.message)
    }
  })

  useEffect(() => {
    getSportsLimit({
      variables: {
        count_bet: cartSport?.pick_list?.length || 1,
        cate: category
      }
    })
  }, [location.pathname, cartSport])

  const handleChangeTotalBet = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maxBetAmount = limitBetting?.BettingLimitsVirtualSportQuery.max_bet_amount || 0
    const availableMoney = user?.mMoney || 0
    const value = Number(e.target.value.replace(/\D/g, ''))

    const maxAllowedBet = Math.min(maxBetAmount, availableMoney)

    if (value > maxAllowedBet) {
      setCrashBet(handleAddMaxBet(maxAllowedBet))
    } else {
      setCrashBet(handleAddMaxBet(value))
    }
  }

  const addExtraBet = (value: number) => {
    const maxBetAmount = limitBetting?.BettingLimitsVirtualSportQuery.max_bet_amount || 0
    const availableMoney = user?.mMoney || 0
    const newBetAmount = crashBet + value

    const maxAllowedBet = Math.min(maxBetAmount, availableMoney)

    if (newBetAmount > maxAllowedBet) {
      setCrashBet(handleAddMaxBet(maxAllowedBet))
    } else {
      setCrashBet(handleAddMaxBet(newBetAmount))
    }
  }
  const handleAddMaxBet = (value: number) => {
    if (totalBetLimit * value > (limitBetting?.BettingLimitsVirtualSportQuery?.max_bet_payout ?? 0)) {
      return Math.floor(Number((limitBetting?.BettingLimitsVirtualSportQuery?.max_bet_payout ?? 0) / totalBetLimit))
    } else {
      return Number(value)
    }
  }

  useEffect(() => {
    if (cartSport.pick_list.length === 0) {
      setCrashBet(0)
    } else {
      setCrashBet(handleAddMaxBet(crashBet))
    }
  }, [JSON.stringify(cartSport.pick_list)])

  const handleBetting = async () => {
    await betMutation()
  }

  const renderVirtualSportsCart = () => {
    return cartSport?.pick_list?.length > 0 ? (
      cartSport?.pick_list?.map((item: BetItem, index: number) => {
        return (
          <div key={index} className='bg-black-3 rounded relative'>
            <button
              className='absolute top-4 right-2'
              onClick={() =>
                dispatch(
                  removeItemFromCartSport({
                    betId: item.betId
                  })
                )
              }
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                x='0px'
                y='0px'
                width='15'
                height='15'
                viewBox='0 0 24 24'
                fill='#ffffff'
              >
                <path d='M 4.9902344 3.9902344 A 1.0001 1.0001 0 0 0 4.2929688 5.7070312 L 10.585938 12 L 4.2929688 18.292969 A 1.0001 1.0001 0 1 0 5.7070312 19.707031 L 12 13.414062 L 18.292969 19.707031 A 1.0001 1.0001 0 1 0 19.707031 18.292969 L 13.414062 12 L 19.707031 5.7070312 A 1.0001 1.0001 0 0 0 18.980469 3.9902344 A 1.0001 1.0001 0 0 0 18.292969 4.2929688 L 12 10.585938 L 5.7070312 4.2929688 A 1.0001 1.0001 0 0 0 4.9902344 3.9902344 z'></path>
              </svg>
            </button>
            <div className='p-2 pt-4'>
              {isGreyhoundsOrHorse.includes(item.virtualData.cate2) ? (
                <>
                  <p className='flex-auto font-normal text-white text-14  max-w-[calc(100%_-_24px)]'>{`${item.virtualData.away}. ${item.virtualData.home}`}</p>
                  <p className='text-white text-14 font-normal'>{item?.virtualData.title}</p>
                  {/* <p className='text-[#d65708] text-14 font-normal'>
                    {item.virtualData.cate2KoName || item.virtualData.cate2}
                  </p> */}
                  <div className='text-white font-semibold text-14 flex justify-between'>
                    <p className='text-[#d65708] text-14 font-normal'>
                      {item.virtualData.cate2KoName || item.virtualData.cate2}
                    </p>
                    <span className='text-primary'>{item.rate.toFixed(2)}</span>
                  </div>
                </>
              ) : (
                <>
                  <p className='font-normal text-white text-12 max-w-[calc(100%_-_24px)]'>
                    {item.virtualData.cate1KoName} - {item.betTeam}
                  </p>
                  <p className='font-normal text-white text-12 max-w-[calc(100%_-_24px)]'>
                    {item.virtualData.home} vs {item.virtualData.away}
                  </p>
                  {/* <p className='text-primary text-14'>{item?.virtualData.title}</p> */}
                  <div className='text-white font-semibold text-14 flex justify-between'>
                    <p className='text-primary text-14 font-normal'>{item?.virtualData.title}</p>
                    <span className='text-primary'>{item.rate.toFixed(2)}</span>
                  </div>
                </>
              )}
            </div>
          </div>
        )
      })
    ) : (
      <>
        <p className='text-12 text-center mx-auto pt-2 pb-4'>선택된 배팅내역이 없습니다.</p>
      </>
    )
  }

  const renderCartHistory = () => {
    return betData?.BetHistoryQuery?.data.map((item: BetHistoryGroup, index: number) => {
      let resultStr = ''
      let resultClass = ''

      switch (item.result) {
        case EStatusBetting.WAIT:
          resultStr = '대기'
          resultClass = 'bet-status-wait'
          break
        case EStatusBetting.LOSE:
          resultStr = '낙첨'
          resultClass = 'bet-status-lose'
          break
        case EStatusBetting.WIN:
          resultStr = '당첨'
          resultClass = 'bet-status-win'
          break
        case EStatusBetting.CANCEL:
        case EStatusBetting.SPECIAL:
          resultStr = '취소'
          resultClass = 'bet-status-cancel'
          break
        default:
          break
      }
      return (
        <div
          key={item._id}
          className={`border-t ${index > 0 ? 'border-blue-1' : 'border-none'} flex flex-col gap-y-2 py-4`}
        >
          <div className='grid grid-cols-[4fr_8fr] gap-x-4 gap-y-1'>
            {item.bets.map((bet: BetItemHistory, index) => {
              return (
                <Fragment key={JSON.stringify(bet)}>
                  {location.pathname === pathGreyhounds || location.pathname === pathHorse ? (
                    ''
                  ) : (
                    <>
                      <p className='flex-4 text-12 text-[#AAAAAA]'>배팅타입</p>
                      <p className='flex-8 text-12'>{bet.betDescription}</p>

                      <p className='flex-4 text-12 text-[#AAAAAA]'>배팅접수</p>
                      <p className='flex-8 text-12'>{bet.betDetail}</p>

                      <p className='flex-4 text-12 text-[#AAAAAA]'>경기결과</p>
                      <p className='flex-8 text-12'>
                        {bet.status !== EStatusBetting.CANCEL &&
                        bet.status !== EStatusBetting.WAIT &&
                        bet.status !== EStatusBetting.SPECIAL
                          ? bet.betResult
                          : '-'}
                      </p>
                      <div className='col-span-2 h-px bg-[#3C3C3C] my-1'></div>
                    </>
                  )}
                </Fragment>
              )
            })}
          </div>
          <div className='grid grid-cols-[4fr_8fr] gap-x-4 gap-y-1'>
            {(location.pathname === pathGreyhounds || location.pathname === pathHorse) &&
              item.bets.map((bet: BetItemHistory, index) => (
                <Fragment key={JSON.stringify(bet)}>
                  <p className='flex-4 text-12 text-[#AAAAAA]'>배팅접수</p>
                  <p className='flex-8 text-12'>{bet.betDetail}</p>

                  <p className='flex-4 text-12 text-[#AAAAAA]'>경기결과</p>
                  <p className='flex-8 text-12'>
                    {bet.status !== EStatusBetting.CANCEL &&
                    bet.status !== EStatusBetting.WAIT &&
                    bet.status !== EStatusBetting.SPECIAL
                      ? bet.betResult
                      : '-'}
                  </p>
                  <div className='col-span-2 h-px bg-[#3C3C3C] my-1'></div>
                </Fragment>
              ))}
            <p className='flex-4 text-12 text-[#AAAAAA]'>배팅시간</p>
            <p className='flex-8 text-12'>{item.regdate}</p>
            <p className='flex-4 text-12 text-[#AAAAAA]'>예상당첨금액</p>
            <p className='flex-8 text-12'>
              <span className={`text-[#fff]`}>(</span>
              배팅{' '}
              <span
                className={` ${location.pathname === pathGreyhounds || location.pathname === pathHorse ? 'text-[#629A49]' : 'text-text-cart-1'}`}
              >
                {item.betAmount} <span className='text-[#fff]'>X</span>
              </span>{' '}
              배당{' '}
              <span
                className={` ${location.pathname === pathGreyhounds || location.pathname === pathHorse ? 'text-[#629A49]' : 'text-text-cart-1'}`}
              >
                {item.result_rate.toFixed(2)}
              </span>
              <span
                className={` ${location.pathname === pathGreyhounds || location.pathname === pathHorse ? '' : 'text-text-cart-1'}`}
              >
                )
              </span>{' '}
              = <span className='text-text-cart-2'>{item.estimateAmount}</span>
              <span className={`text-[#fff]`}>원</span>
            </p>
            <p className='flex-4 text-12 text-[#AAAAAA]'>결과당첨금액</p>
            <p className='flex-8 text-12 text-white'>
              <span className='text-text-cart-2'>{item.winAmount}</span>원
            </p>
            <p className='flex-4 text-12 text-[#AAAAAA]'>배팅결과</p>
            <p className={cn('flex-8 text-12', resultClass)}>{item.cartStatusKoName}</p>
          </div>
        </div>
      )
    })
  }

  return (
    <div className='w-full mt-4'>
      <div className='flex justify-between'>
        <button
          className={`w-1/2 h-10 font-normal hover:text-primary-2 hover:border-b hover:border-b-primary-2 ${tab === SPORT_CART_BET ? 'text-primary-2 border-b border-b-primary-2' : 'text-white'}`}
          onClick={() => setTab(SPORT_CART_BET)}
        >
          배팅카트 {cartSport?.pick_list?.length}
        </button>
        <button
          className={`w-1/2 h-10 font-normal hover:text-primary-2 hover:border-b hover:border-b-primary-2 ${tab === SPORT_CART_HISTORY ? 'text-primary-2 border-b border-b-primary-2' : 'text-white'}`}
          onClick={() => {
            if (tab === SPORT_CART_HISTORY) return

            refetchBetData()
            setTab(SPORT_CART_HISTORY)
          }}
        >
          배팅 기록
        </button>
      </div>
      {tab === SPORT_CART_BET && (
        <>
          <div>
            <div className='rounded'>
              <div className='flex font-normal justify-between text-primary-2 border-b border-[#3C3C3C] p-2'>
                <div className='right number bet-cart-cnt text-14'>
                  {cartSport?.pick_list?.length}
                  폴더
                </div>
                <div className='left'>
                  <button className='text-[#707478] text-14 font-normal' onClick={() => dispatch(removeAllCartSport())}>
                    전체지우기
                  </button>
                </div>
              </div>
              <div className='flex flex-col gap-2'>{renderVirtualSportsCart()}</div>
              <div className='border border-[#3C3C3C] pr-1 pl-3 flex justify-between items-center gap-10 py-2'>
                <p className='text-12 font-bold flex-shrink-0'>배팅</p>
                <div className='flex items-center gap-2'>
                  <button onClick={() => setCrashBet(0)}>
                    <img
                      src='/assets/images/icons/icon-rotate-white.svg'
                      alt='Icon Rotate'
                      title='Icon Rotate'
                      width='16'
                      height='19'
                    />
                  </button>
                  <input
                    disabled
                    className='input-primary auto-fill-input w-full placeholder:text-gray-500 focus:outline-none text-white text-14 h-8 p-3 rounded border-2 border-primary bg-black  '
                    placeholder='금액을 입력해주세요.'
                    value={formatCurrency(crashBet, ',')}
                    onChange={handleChangeTotalBet}
                  />
                </div>
              </div>
              <div className='flex justify-between items-center gap-1 text-12 my-3'>
                <button
                  className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                  onClick={() => addExtraBet(5000)}
                >
                  오천
                </button>
                <button
                  className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                  onClick={() => addExtraBet(10000)}
                >
                  일만
                </button>
                <button
                  className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                  onClick={() => addExtraBet(50000)}
                >
                  오만
                </button>
                <button
                  className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                  onClick={() => addExtraBet(100000)}
                >
                  십만
                </button>
                <button
                  className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                  onClick={() => addExtraBet(1000000)}
                >
                  백만
                </button>
                <button
                  className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                  onClick={() => {
                    addExtraBet(user.mMoney)
                  }}
                >
                  맥스
                </button>
              </div>
              <div className='p-2 bg-[#0b0d10]'>
                <div>
                  <ul className='flex flex-col gap-1'>
                    <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                      <p>총 배당:</p>
                      <p>{formattedTotalBetRate} 배</p>
                    </li>
                    <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                      <p>당첨 예상금액</p>
                      <p>{formatCurrency(totalMoney, ',')} 원</p>
                    </li>
                    {/* MIN */}
                    <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                      <p>최소 배팅금액</p>
                      <p>{formatCurrency(limit?.min_bet_amount || 0, ',')} 원</p>
                    </li>

                    {/* MAX */}
                    <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                      <p>최대 배팅금액</p>
                      <p>{formatCurrency(limit?.max_bet_amount || 0, ',')} 원</p>
                    </li>

                    {/* MAX REWARD */}
                    <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                      <p>최대 당첨금액</p>
                      <p>{formatCurrency(limit?.max_bet_payout || 0, ',')} 원</p>
                    </li>

                    {/* COUNT ... */}
                    <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                      <p>최대배당</p>
                      <p>{formatCurrency(limit?.max_payout || 0, ',')} 배</p>
                    </li>
                  </ul>
                </div>
                <button
                  className={cn(
                    'rounded-md bg-[#14161b] block w-full text-primary-2 py-2 my-2 font-bold hover:bg-primary-2 hover:text-black',
                    {
                      '!bg-gray-5 pointer-events-none hover:!bg-gray-5 ': loading
                    }
                  )}
                  disabled={!token || loading}
                  onClick={cartSport.pick_list.length ? handleBetting : () => {}}
                >
                  배팅하기
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {tab === SPORT_CART_HISTORY && user ? (
        betData?.BetHistoryQuery?.data && betData?.BetHistoryQuery?.data.length > 0 ? (
          <div className='flex flex-col mb-[100px]'>{renderCartHistory()}</div>
        ) : (
          <div className='flex flex-col p-5 bg-black w-[95%] mx-auto text-12 rounded'>
            <p className='text-[#707478] mb-[20px]'>배팅내역이 존재하지 않습니다.</p>
            <p className='text-[#707478]'>*자세한 내역 및 취소요청은 배팅내역</p>
            <p className='text-[#707478]'>페이지에서 확인 가능합니다.</p>
          </div>
        )
      ) : null}
    </div>
  )
}

{
  /* {showCancelBtn && (
                      <div className='flex justify-between w-4/5 mx-auto mt-1'>
                        <button
                          className='bg-black w-1/2 h-6 text-white text-12'
                          onClick={() =>
                            cancelBetMutation({
                              context: { apiName: 'sports' },
                              variables: {
                                bet_id: item?.id
                              },
                              onCompleted: (data) => {
                                toast.success(data?.CancelBetMutation?.message)
                                refetchBetData()
                              }
                            })
                          }
                        >
                          취소
                        </button>
                        <button
                          className='bg-[#14161b] text-primary-2 font-bold hover:bg-primary-2 hover:text-black w-1/2 h-6 text-12'
                          onClick={() =>
                            deleteBetMutation({
                              context: { apiName: 'sports' },
                              variables: {
                                bet_id: item?.id
                              },
                              onCompleted: (data) => {
                                toast.success(data?.DeleteBetMutation?.message ?? '배팅 성공 삭제')
                                refetchBetData()
                              },
                              onError: (error) => {
                                toast.error(error.message)
                              }
                            })
                          }
                        >
                          삭제
                        </button>
                      </div>
                    )} */
}
