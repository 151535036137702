// import './index.scss'
export default function Developing() {
  return (
    <div className='mt-2 lg:max-h-[calc(100dvh-150px)] overflow-y-auto sidebar__overflow' data-aos='fade-up'>
      <div className='flex flex-col md:flex-row gap-4 items-center py-[5px] px-[20px] text-[14px] justify-between'>
        <div className='w-[100vw] h-[100dvh] bg-[#bab3b3] flex items-center justify-center popup-container'>
          <div className='flex flex-col gap-5 text-center popup-text'>
            <img src='/assets/images/not_found.webp' alt='' srcSet='' />

            <h1 className='text-5xl font-bold text-black'>작업중입니다</h1>
            <h1 className='text-5xl font-bold text-primary-2'>나중에 다시 오시기 바랍니다</h1>
            {/* <p className='text-16 font-bold text-black'>나중에 다시 오시기 바랍니다</p> */}
          </div>
        </div>
      </div>
    </div>
  )
}
