/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { TableColumnsType } from 'antd'
import DataTable from 'src/components/Table/DataTable'

export default function UsageHistory() {
  const BattleColumns: TableColumnsType<any> = [
    {
      title: '지급받은 날짜',
      key: 'field1',
      width: '20%',
      align: 'center',
      dataIndex: 'field1'
    },
    { title: '쿠폰금액', key: 'field2', width: '50%', align: 'center', dataIndex: 'field2' },
    {
      title: '상태',
      dataIndex: 'field3',
      key: 'field3',
      width: '10%',
      align: 'center'
    },
    {
      title: '사용/만료 날짜',
      key: 'field4',
      width: '10%',
      align: 'center',
      dataIndex: 'field4'
    }
  ]

  const data: readonly any[] | undefined = []

  return (
    <div className='battle__type mt-5 md:mt-0'>
      <div className='flex flex-wrap '>
        <DataTable
          className='w-full'
          columns={BattleColumns}
          rowClassName={(record, index) => {
            return index % 2 === 0 ? 'even' : 'odd'
          }}
          locale={{
            emptyText: '쿠폰내역이 존재하지 않습니다.'
          }}
          // expandable={{
          //   expandedRowRender: (record: any) => <div dangerouslySetInnerHTML={{ __html: record.content }}></div>,
          //   expandRowByClick: true,
          //   expandIconColumnIndex: -1
          // }}
          // pagination={{
          //   position: ['bottomCenter'],
          //   total: query.total,
          //   pageSize: query.limit
          // }}
          // dataSource={data}
        />
      </div>
    </div>
  )
}
