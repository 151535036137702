import axios from 'axios'
import { useEffect, useState } from 'react'
import http from 'src/libs/utils/axios'
import { XmlData } from 'src/types/betHistory.type'

export const BetHistory = () => {
  const [query, setQuery] = useState({ limit: 10, page: 1 })
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState<XmlData[]>()
  const columns = [
    // { title: 'id', fieldName: 'uuid', width: '500' },
    { title: '경기시간', fieldName: 'date', width: '500' },
    { title: '리그명', fieldName: 'league', width: '500' },
    { title: '배팅타입', fieldName: 'type', width: '500' },
    { title: '홈팀vs원정팀', fieldName: 'team', width: '500' },
    { title: '수량', fieldName: 'amount', width: '500' },
    { title: '경기결과', fieldName: 'result', width: '500' },
    { title: '배팅결과', fieldName: 'status', width: '500' }
  ]
  const fetchDataFake = 0
  useEffect(() => {
    axios
      .get(`https://bti.spobulls.net/data-xml/xmls?limit=${query?.limit || 10}&page=${query?.page || 1}`)
      .then((res) => {
        if (res?.data?.error_code === 200) {
          console.log('🚀 ~ .then ~ res?.data?.data:', res?.data?.data)
          setData(res?.data?.data?.data)
          setTotal(res?.data?.data?.total)
        }
      })
  }, [fetchDataFake, query?.limit, query?.page])

  const itemsPerPage = query.limit
  const totalPages = Math.ceil(total / itemsPerPage)

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setQuery({ ...query, page: newPage })
      setCurrentPage(newPage)
    }
  }
  const renderStatus = (status: string) => {
    switch (status) {
      case 'Won':
        ;<h3 className='w-full text-center self-center justify-center place-self-center text-[#D60000]'>{status}</h3>
        break

      default:
        break
    }
  }

  const statusClasses = {
    Won: 'text-[#5093ff]',
    Lose: 'text-[#D60000]',
    Wait: 'text-[#F8B42F]'
  }

  //when data is compelete open cmt and change fields
  // const dataExample = [
  //   {
  //     betAmount: 50000,
  //     date: '2024-08-21 18:32:44',
  //     estimateAmount: 300000,
  //     isCancelable: false,
  //     now: 1724833596,
  //     rate: 6,
  //     settlementStatus: 'Closed',
  //     winAmount: 0,
  //     betList: [
  //       {
  //         awayScore: -1,
  //         awayTeamName: '크로아티아',
  //         betDetail: '승무패(3) - 크로아티아',
  //         betResult: '',
  //         amount: 10000,
  //         homeScore: -1,
  //         homeTeamName: '스페인',
  //         leagueName: 'Euro Cup',
  //         selectRate: 3,
  //         selectedLine: 3,
  //         startDate: '2024-08-21 18:26:00',
  //         result: 'Lost',
  //         status: 'Closed',
  //         type: 'creditcustomer'
  //       },
  //       {
  //         awayScore: -1,
  //         awayTeamName: '크로아티아',
  //         betDetail: '승무패(3) - 크로아티아',
  //         betResult: '',
  //         homeScore: -1,
  //         homeTeamName: '스페인',
  //         leagueName: 'Euro Cup',
  //         selectRate: 3,
  //         selectedLine: 3,
  //         startDate: '2024-08-21 18:26:00',
  //         result: 'Wait',
  //         status: 'Closed',
  //         amount: 20000,
  //         type: 'reserve'
  //       }
  //     ]
  //   },

  //   {
  //     betAmount: 50000,
  //     date: '2024-08-21 18:32:44',
  //     estimateAmount: 300000,
  //     isCancelable: false,
  //     now: 1724833596,
  //     rate: 6,
  //     settlementStatus: 'Closed',
  //     winAmount: 0,
  //     betList: [
  //       {
  //         awayScore: -1,
  //         awayTeamName: '크로아티아',
  //         betDetail: '승무패(3) - 크로아티아',
  //         betResult: '',
  //         amount: 10000,
  //         homeScore: -1,
  //         homeTeamName: '스페인',
  //         leagueName: 'Euro Cup',
  //         selectRate: 3,
  //         selectedLine: 3,
  //         startDate: '2024-08-21 18:26:00',
  //         result: 'Lost',
  //         status: 'Closed',
  //         type: 'creditcustomer'
  //       },
  //       {
  //         awayScore: -1,
  //         awayTeamName: '크로아티아',
  //         betDetail: '승무패(3) - 크로아티아',
  //         betResult: '',
  //         homeScore: -1,
  //         homeTeamName: '스페인',
  //         leagueName: 'Euro Cup',
  //         selectRate: 3,
  //         selectedLine: 3,
  //         startDate: '2024-08-21 18:26:00',
  //         result: 'Wait',
  //         status: 'Closed',
  //         amount: 20000,
  //         type: 'reserve'
  //       }
  //     ]
  //   }
  // ]

  // const renderResults = (value: string | undefined) => {
  //   switch (value) {
  //     case 'Wait':
  //       return (
  //         <button
  //           disabled
  //           className='bg-[#4986e8] ml-5 text-12 rounded-[5px] px-4 py-1 min-w-[100px] md:min-w-24 max-h-8'
  //         >
  //           정산완료
  //         </button>
  //       )
  //     case 'Closed':
  //       return (
  //         <button disabled className='bg-[#be311e] ml-5 rounded-[5px] px-4 py-1 min-w-[100px] md:min-w-24 max-h-8'>
  //           취소
  //         </button>
  //       )
  //   }
  // }

  // const layoutDataExample = () => {
  //   return dataExample?.map((item, index) => (
  //     <>
  //       <div
  //         className={`mt-4 py-2 px-2 min-w-[800px] mb-[30px] rounded-[5px] ${index % 2 == 1 ? 'bg-primary-8' : 'bg-primary-9'}`}
  //       >
  //         {item?.betList?.map((betList, indexBetList) => (
  //           <div key={indexBetList}>
  //             <div className='grid grid-cols-7 py-2 text-[13px] line-battle'>
  //               <h3 className='w-full text-left self-center justify-center place-self-center'>
  //                 {betList?.startDate || '-'}
  //               </h3>
  //               <h3 className='w-full text-center self-center justify-center place-self-center'>
  //                 {betList?.leagueName || '-'}
  //               </h3>
  //               <h3 className='w-full text-center self-center justify-center place-self-center'>
  //                 {betList?.type || '-'}
  //               </h3>
  //               <h3 className='w-full text-center self-center justify-center place-self-center'>
  //                 {betList?.homeTeamName + 'vs' + betList?.awayTeamName || '-'}
  //               </h3>
  //               <h3 className='w-full text-center self-center justify-center place-self-center'>
  //                 {betList?.amount || '-'}
  //               </h3>
  //               <h3
  //                 className={`w-full text-center self-center justify-center place-self-center ${statusClasses[betList?.result]} font-semibold`}
  //               >
  //                 {betList?.result || '-'}
  //               </h3>
  //               <h3 className='w-full text-right pr-[22px] self-center justify-center place-self-center'>
  //                 {betList?.status || '-'}
  //               </h3>
  //             </div>
  //           </div>
  //         ))}
  //         <div className='my-4 w-full min-w-[900px] text-[13px] flex justify-end items-center'>
  //           <p className=''>
  //             예상당첨금액 : (배팅금
  //             <span className='text-green-1'> {item.betAmount?.toLocaleString()}</span> X 배당률
  //             <span className='text-green-1'> {item.rate}</span>) =
  //             <span className='text-primary-6 mr-5'>{item.estimateAmount?.toLocaleString()} 원</span>
  //             결과당심금액
  //             <span className='text-green-1'> {item.winAmount?.toLocaleString()} </span>원
  //             {renderResults(item.settlementStatus)}
  //           </p>
  //         </div>
  //       </div>
  //     </>
  //   ))
  // }

  return (
    <div className='battle__type mt-5 xs:mt-0'>
      <div className='battle__type'>
        {/* DataTable Bet battle EX 2*/}

        <div className='block overflow-x-auto'>
          <div className='grid grid-cols-7 bg-[#101216] min-w-[800px] px-2 py-4'>
            {columns.map((item, index) => {
              return (
                <h3
                  className={`flex-shrink-1 whitespace-nowrap w-full text-[14px] ${index === 0 ? 'text-left' : 'text-center'} ${index === 6 ? 'text-right pr-[22px]' : ''}`}
                  key={index}
                >
                  {item.title}
                </h3>
              )
            })}
          </div>

          {data && data?.length > 0 ? (
            //when data is compelete open cmt and change fields
            // layoutDataExample()

            data.map((item, index) => (
              <div
                key={index}
                className={`mt-4 py-2 px-2 min-w-[800px] mb-[30px] rounded-[5px] ${index % 2 == 1 ? 'bg-primary-8' : 'bg-primary-9'}`}
              >
                <div className={`grid grid-cols-8 py-2 text-[13px] 'line-battle'}`}>
                  {/* <h3 className='w-full text-left self-center justify-center place-self-center'>
                    {item.dataXml?.Bets?.cust_id || '-'}
                  </h3> */}
                  <h3 className='w-full text-left self-center justify-center place-self-center'>{item?.date || '-'}</h3>
                  <h3 className='w-full text-center self-center justify-center place-self-center'>
                    {item?.league || '-'}
                  </h3>
                  <h3 className='w-full text-center self-center justify-center place-self-center'>
                    {item?.type || '-'}
                  </h3>
                  <h3 className='w-full text-center self-center justify-center place-self-center'>
                    {item?.team || '-'}
                  </h3>
                  <h3 className='w-full text-center self-center justify-center place-self-center'>
                    {item?.amount || '-'}
                  </h3>
                  <h3
                    className={`w-full text-center self-center justify-center place-self-center ${statusClasses[item?.result]} font-semibold`}
                  >
                    {item?.result || '-'}
                  </h3>
                  <h3 className='w-full text-right pr-[22px] self-center justify-center place-self-center'>
                    {item?.status || '-'}
                  </h3>
                </div>

                {/* <div className='mt-4'>
                  <p className='flex w-full text-[13px]'>
                    <span className='w-[22.3%] self-center place-self-center'>
                      배팅접수시간: {item.dataXml?.Credit?.Purchases?.Purchase.CreationDateUTC}
                    </span>
                    <span className='flex justify-end w-full'>
                      <span className='self-center justify-center place-self-center mr-2'>
                        예상당첨금액 : (배팅금
                        <span className='text-green-1'>
                          {item.dataXml?.Credit?.Purchases?.Purchase?.Selections?.Selection?.Changes?.Change?.Bets?.Bet?.Amount.toLocaleString()}
                        </span>
                        X 배당률 <span className='text-green-1'>5</span>) =
                      </span>
                    </span>
                  </p>
                </div> */}
              </div>
            ))
          ) : (
            <div className='bg-[#25292c] p-16 flex justify-center place-items-center'>
              <div className='text-center'>
                <svg width='64' height='41' viewBox='0 0 64 41' xmlns='http://www.w3.org/2000/svg'>
                  <g transform='translate(0 1)' fill='none' fillRule='evenodd'>
                    <ellipse fill='#f5f5f5' cx='32' cy='33' rx='32' ry='7'></ellipse>
                    <g fillRule='nonzero' stroke='#d9d9d9'>
                      <path d='M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z'></path>
                      <path
                        d='M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z'
                        fill='#fafafa'
                      ></path>
                    </g>
                  </g>
                </svg>
                <p className='mt-2 text-14'>NoData</p>
              </div>
            </div>
          )}
        </div>

        {/* Pagination Controls */}
        {data && data?.length > 0 && (
          <div className='mt-2 mb-4 flex justify-center'>
            <button
              className={`px-4 py-2 bg-primary-10  text-white rounded ${currentPage === 1 ? 'opacity-15' : 'opacity-1'}`}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <img src='/public/assets/arrow-left.svg' alt='' />
            </button>

            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                className={`w-[32px] font-dotum font-[600] text-[14px] h-[32px] rounded mx-2 ${currentPage === index + 1 ? 'bg-primary text-black' : 'bg-primary-10 text-white hover:text-primary'}`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            <button
              className={`px-4 py-2 bg-primary-10 text-white rounded ${currentPage === totalPages ? 'opacity-15' : 'opacity-1'}`}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <img src='/public/assets/arrow-right.svg' alt='' />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
