import { gql } from '@apollo/client'

export const GET_DISPLAY_QUERY = gql`
  query DisplayQuery {
    DisplayQuery {
      menu {
        key
        value
        link
      }
      mobile_icons {
        key
        image
        link
        value
      }
      banner {
        target
        link
        image
      }
      left_menu {
        value
        key
        link
        image
      },      
      top_menu {
        value
        key
        link
        image
      }
      slider {
        target
        link
        image
      }
      game_category_banner {
        target
        link
        image
      }
      top_left_menu {
        key
        value
        link
        image
      }
      bottom_left_menu {
        key
        value
        link
        image
      }
    }
  }
`
