import { useLazyQuery } from '@apollo/client'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import path from 'src/constants/path'
import { sportsApi } from 'src/libs/apis/axios/sports.api'
import { GET_EXCHANGE_SPORTS_QUERY } from 'src/libs/apis/graphql/queries/sport.query'
import { useLiveSportSearchStore } from 'src/libs/hooks/store/useLiveSportSearchStore'
import {
  addDataRateInfoLiveList as addDataRateInfoList,
  addDataToLiveSearchListPure as addDataToSearchListPure,
  resetDataBoardBetLive as resetDataSportSearch,
  setExchangeRateLiveSport as setExchangeRateSport,
  setFetchAllApiLive as setFetchAllApi,
  setFetchApiGetAllLiveSports as setFetchApiGetAllSports,
  setIsFirstTimeFetchLiveSport as setIsFirstTimeFetch,
  setOpenBoardBetLiveSport as setOpenBoardBetSport
} from 'src/libs/stores/live-sport-search.reducer'
import { transformDataLiveSport } from 'src/libs/utils/live-sports.func'
import { ExchangeRateInfoQuery } from 'src/types/live-sports.type'
import { useCommonStore } from './../../../libs/hooks/store/useCommonStore'
import { getAccessTokenFromLS, getAccessTokenFromSS, getTokenDynamic } from 'src/libs/utils/auth'
import { RootState } from 'src/libs/utils/store'
const DEFAULT_PAGE_LIMIT = 20

export const useLiveSports = () => {
  const abortControllerRef = useRef<AbortController>(new AbortController())
  const { dataLiveSportSearch, isOpenBoardBetSport, isFirstTimeFetch, isLoadingRateInfo, isLoadingLiveSports } =
    useLiveSportSearchStore()
  const { categoriesLiveSports } = useCommonStore()
  const [openMenuCategory, setOpenMenuCategory] = useState(false)
  const readTokenFromLS = useSelector((state: RootState) => state.auth.readTokenFromLS)
  const user = useSelector((state: RootState) => state.auth.user)
  const token = useMemo(() => getTokenDynamic(readTokenFromLS), [user])
  const dispatch = useDispatch()
  const [paramSearch] = useSearchParams()
  const [isLoadingChangePagination, setIsLoadingChangePagination] = useState(false)
  const sportsCodeParams = paramSearch.get('sportsCode')
  const nationCodeParams = paramSearch.get('nationCode')
  const leagueCodeParams = paramSearch.get('leagueCode')
  const pageParams = paramSearch.get('page')
  const [pagination, setPagination] = useState<{
    current_page: number
    total: number
  }>({
    current_page: pageParams ? +pageParams : 1,
    total: 1
  })

  const [getExchangeRateSports] = useLazyQuery<
    ExchangeRateInfoQuery,
    {
      type: string
    }
  >(GET_EXCHANGE_SPORTS_QUERY, {
    context: { apiName: 'sports' },
    onCompleted: (data) => {
      if (data && data.ExchangeRateInfoQuery.length) {
        dispatch(setExchangeRateSport(data.ExchangeRateInfoQuery))
      }
    }
  })

  const navigate = useNavigate()

  const categoriesLiveSportsWithAllOpts = useMemo(() => {
    return [
      {
        count: categoriesLiveSports.reduce((sum, cur) => sum + cur.count, 0),
        imgPath: '/categoryAll.png',
        nationList: [],
        sportsCode: 0,
        sportsName: 'All',
        sportsNameEn: 'All'
      },
      ...categoriesLiveSports
    ]
  }, [categoriesLiveSports?.length])

  const handleToggleMenuCategory = () => {
    setOpenMenuCategory((prev) => !prev)
  }

  const handleInitRules = async () => {
    document.getElementById('main-content')?.scrollTo({
      top: 0
    })
    await getExchangeRateSports({
      variables: {
        type: 'realtime'
      }
    })
  }

  async function handleGetInfoSportsPage({ page = 1 }: { page: number }) {
    let isCancel = false
    try {
      const paramsFilter = {
        limit: DEFAULT_PAGE_LIMIT,
        page: page,
        sportsCode: sportsCodeParams || '',
        nationCode: nationCodeParams || '',
        leagueCode: leagueCodeParams || ''
      }
      dispatch(setFetchApiGetAllSports(true))
      // dispatch(setOpenBoardBetSport(false))
      const res = await sportsApi.getAllLiveSports({
        params: paramsFilter,
        options: {
          signal: abortControllerRef.current.signal,
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      })
      if (res.data) {
        dispatch(
          addDataToSearchListPure({
            liveSportList: res.data.data.detailInfo.data
              .map(transformDataLiveSport)
              .filter((sportItem) => sportItem !== null)
          })
        )
        setPagination({
          current_page: page,
          total: res.data ? res.data.data.detailInfo.total : 1
        })
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.code === 'ERR_CANCELED') {
        isCancel = true
        return
      }
      dispatch(
        addDataToSearchListPure({
          liveSportList: []
        })
      )
      console.log('err', error)
      toast.error('문제가 발생했습니다. 나중에 다시 시도 해주십시오.')
      dispatch(setFetchAllApi(false))
      isFirstTimeFetch && dispatch(setIsFirstTimeFetch(false))
    } finally {
      dispatch(setFetchApiGetAllSports(false))
      isFirstTimeFetch && dispatch(setIsFirstTimeFetch(false))
      !isCancel && setIsLoadingChangePagination(false)
    }
  }

  const handleChangePaginationSport = async (page: number) => {
    setIsLoadingChangePagination(true)
    window.history.pushState(
      null,
      '',
      `${path.live_sport}?page=${page}${sportsCodeParams ? '&sportsCode=' + sportsCodeParams : ''}${nationCodeParams ? '&nationCode=' + nationCodeParams : ''}${leagueCodeParams ? '&leagueCode=' + leagueCodeParams : ''}`
    )
    abortControllerRef.current.abort()
    abortControllerRef.current = new AbortController()
    await handleGetInfoSportsPage({ page })
  }
  const handleChangeCategory = ({ sportsCode }: { sportsCode: number }) => {
    navigate({
      pathname: path.live_sport,
      search: '?' + `sportsCode=${sportsCode}`
    })
    dispatch(
      addDataToSearchListPure({
        liveSportList: []
      })
    )
    dispatch(
      addDataRateInfoList({
        rateInfoList: []
      })
    )
    dispatch(setOpenBoardBetSport(false))
  }

  useEffect(() => {
    setIsLoadingChangePagination(true)
    handleInitRules()
    handleGetInfoSportsPage({ page: pageParams ? Number(pageParams) : 1 })
    return () => {
      abortControllerRef.current.abort()
      dispatch(resetDataSportSearch())
      dispatch(setIsFirstTimeFetch(false))
    }
  }, [])

  useEffect(() => {
    if (Number(sportsCodeParams) !== 0) {
      const categoryTarget = categoriesLiveSports.find((item) => item.sportsCode === Number(sportsCodeParams))
      if (!categoryTarget) {
        return
      }
      if (categoryTarget?.maintenance) {
        return
      }
    }
    if (!isFirstTimeFetch && (sportsCodeParams !== null || nationCodeParams !== null || leagueCodeParams !== null)) {
      window.history.pushState(
        null,
        '',
        `${path.live_sport}?page=${1}${sportsCodeParams && sportsCodeParams !== '0' ? '&sportsCode=' + sportsCodeParams : ''}${nationCodeParams ? '&nationCode=' + nationCodeParams : ''}${leagueCodeParams ? '&leagueCode=' + leagueCodeParams : ''}`
      )
      setIsLoadingChangePagination(true)
      abortControllerRef.current.abort()
      abortControllerRef.current = new AbortController()
      handleGetInfoSportsPage({ page: 1 })
    }
  }, [sportsCodeParams, nationCodeParams, leagueCodeParams])

  return {
    sportList: dataLiveSportSearch || [],
    // categoriesLiveSports: categoriesLiveSports.slice(0, 3),
    categoriesLiveSports: categoriesLiveSportsWithAllOpts,
    isFirstTimeFetch,
    isOpenBoardBetSport,
    isLoadingRateInfo,
    isLoadingLiveSports,
    isLoadingChangePagination,
    pagination,
    pageSize: DEFAULT_PAGE_LIMIT,
    sportsCodeActive: sportsCodeParams ? Number(sportsCodeParams) : 0,
    openMenuCategory,
    categoriesPure: categoriesLiveSports,
    handleToggleMenuCategory,
    handleChangePaginationSport,
    handleChangeCategory
  }
}
