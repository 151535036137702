import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { sportsApi } from 'src/libs/apis/axios/sports.api'
import { useCartLiveSportsStore } from 'src/libs/hooks/store/useCartLiveSportsStore'
import {
  addDataRateInfoLiveList as addDataRateInfoList,
  resetDataBoardBetLive as resetDataBoardBet,
  setFetchApiGetAllRateInfoLive as setFetchApiGetAllRateInfo,
  setOpenBoardBetLiveSport as setOpenBoardBetSport,
  updateSingleDataLiveSearchPure
} from 'src/libs/stores/live-sport-search.reducer'
import {
  addItemToCartLiveSport as addItemToCartSport,
  removeAllItemInMatchAndAddNewItemLive,
  removeItemFromCartLiveSport as removeItemFromCartSport,
  updateItemFromCartLiveSport as updateItemFromCartSport
} from 'src/libs/stores/live-sport.reducer'
import { transformLiveSportDomesticData } from 'src/libs/utils/live-sports.func'
import { DomesticWithKeyMatch, LiveSportItem, LiveSportItemClient } from '../../../types/live-sports.type'
import { getTokenDynamic } from 'src/libs/utils/auth'
import { useMemo } from 'react'
import { RootState } from 'src/libs/utils/store'
import { setShowCart } from 'src/libs/stores/common.reducer'
export const useSelectOptBettingLiveSport = () => {
  const dispatch = useDispatch()
  const { cart, getCountItemInMatch, limitBetting, getAllItemInMatch } = useCartLiveSportsStore()
  const readTokenFromLS = useSelector((state: RootState) => state.auth.readTokenFromLS)
  const user = useSelector((state: RootState) => state.auth.user)
  const token = useMemo(() => getTokenDynamic(readTokenFromLS), [user])
  const handleSelectBetting = ({
    domesticData,
    betId,
    keyMatch,
    rate,
    betTeam
  }: {
    domesticData: DomesticWithKeyMatch
    betId: string
    keyMatch: string
    rate: number
    betTeam: string
  }) => {
    // dispatch(setShowCart(true))
    // logic prevent user betting same match
    // const existingKeyMatchIndex = cart.pick_list.findIndex((item) => item.keyMatch === keyMatch)
    // if (existingKeyMatchIndex !== -1) {
    //   const existingDomesticDataIndex = cart.pick_list.findIndex((item) => item.domesticData.idx === domesticData.idx)
    //   // logic same row
    //   if (existingDomesticDataIndex !== -1) {
    //     const existingCartItemIndex = cart.pick_list.findIndex((item) => item.betId === betId)
    //     // logic check re-pick or change options in row
    //     if (existingCartItemIndex !== -1) {
    //       dispatch(
    //         removeItemFromCartSport({
    //           betId,
    //           domesticIdx: dom`esticData.idx
    //         })
    //       )
    //     } else {
    //       dispatch(
    //         updateItemFromCartSport({
    //           domesticIdx: domesticData.idx,
    //           betId
    //         })
    //       )
    //     }
    //   } else {
    //     toast.warning('농구 - 해외형 에서 승인되지 않은 배팅조합입니다.')
    //   }
    //   return
    // }

    // logic prevent same row
    const existingDomesticDataIndex = cart.pick_list.findIndex((item) => item.domesticData.idx === domesticData.idx)
    // logic same row
    if (existingDomesticDataIndex !== -1) {
      const existingCartItemIndex = cart.pick_list.findIndex((item) => item.betId === betId)
      // logic check re-pick or change options in row
      if (existingCartItemIndex !== -1) {
        dispatch(
          removeItemFromCartSport({
            betId,
            domesticIdx: domesticData.idx
          })
        )
      } else {
        dispatch(
          updateItemFromCartSport({
            domesticIdx: domesticData.idx,
            betId,
            rate,
            betTeam
          })
        )
      }
      return
    }

    dispatch(
      removeAllItemInMatchAndAddNewItemLive({
        keyMatch,
        dateBet: {
          domesticData,
          betId,
          keyMatch,
          rate,
          betTeam
        }
      })
    )
  }

  const handleSelectBettingFromBoard = ({
    domesticData,
    betId,
    keyMatch,
    rate,
    betTeam
  }: {
    domesticData: DomesticWithKeyMatch
    betId: string
    keyMatch: string
    rate: number
    betTeam: string
  }) => {
    if (getCountItemInMatch(keyMatch) > 1) {
      return
    }
    const cartInMatch = getAllItemInMatch(domesticData)
    const existingBetItem = cartInMatch.find((item) => item.betId === betId)
    if (existingBetItem) {
      dispatch(
        removeItemFromCartSport({
          betId,
          domesticIdx: domesticData.idx
        })
      )
      return
    }
    // dispatch(setShowCart(true))
    const totalRate = cart.pick_list.reduce((rateTotal, curr) => rateTotal * Number(curr.rate), 1)
    let incomingTotalRate = totalRate * Number(rate)
    if (cartInMatch.length === 0) {
      incomingTotalRate = totalRate * Number(rate)
    } else {
      incomingTotalRate = (totalRate / Number(cartInMatch[0].rate)) * Number(rate)
    }

    if (incomingTotalRate > (limitBetting?.BettingLimitsSportQuery.max_payout || 0)) {
      toast.error('최대배당을 초과하였습니다.')
      return
    }
    // clear options in same match
    dispatch(
      removeAllItemInMatchAndAddNewItemLive({
        keyMatch,
        dateBet: {
          domesticData: { ...domesticData, isChosenFromBoard: true },
          betId,
          keyMatch,
          rate,
          betTeam
        }
      })
    )
  }

  const handleGetRateInfoList = async ({
    domesticData,
    sportData,
    isForceOpen = false
  }: {
    domesticData?: DomesticWithKeyMatch
    isForceOpen?: boolean
    sportData: LiveSportItemClient
  }) => {
    try {
      if (!domesticData) {
        dispatch(resetDataBoardBet())
        return
      }
      // const isSameData =
      //   dataRateInfoList.length > 0 &&
      //   dataRateInfoList[0].fixture_idx === domesticData.fixture_idx &&
      //   dataRateInfoList[0].league_idx === domesticData.league_idx &&
      //   dataRateInfoList[0].sports_idx === domesticData.sports_idx
      //     ? true
      //     : false
      // if (dataRateInfoList.length && isSameData) {
      //   return
      // }
      dispatch(setFetchApiGetAllRateInfo(true))
      const res = await sportsApi.getSportRateInfoLiveList({
        params: {
          leagueCode: domesticData.league_idx,
          fixtureCode: domesticData.fixture_idx,
          sportsCode: domesticData.sports_idx
        },
        options: {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      })
      if (res.data?.data) {
        const rateInfoList = res.data.data
          .filter((item) => {
            const key = `${item.betid1}|${item.betid2}|${item.betid3}`
            const keyDomesticItem = `${sportData.domestic[0].betid1}|${sportData.domestic[0].betid2}|${sportData.domestic[0].betid3}`
            return key !== keyDomesticItem
          })
          .map((item) => {
            return transformLiveSportDomesticData(item, sportData)
          })
        dispatch(
          addDataRateInfoList({
            rateInfoList: rateInfoList
          })
        )
        dispatch(
          updateSingleDataLiveSearchPure({
            sportData: { ...sportData, count: rateInfoList.length }
          })
        )
      }
    } catch (error) {
      toast.error('문제가 발생했습니다. 나중에 다시 시도 해주십시오.')
      dispatch(setFetchApiGetAllRateInfo(false))
    } finally {
      dispatch(setFetchApiGetAllRateInfo(false))
      isForceOpen && dispatch(setOpenBoardBetSport(true))
      // if (window.innerWidth >= 1024) {
      //   document.getElementById('main-content')?.scrollTo({
      //     top: 0,
      //     left: 0,
      //     behavior: 'smooth'
      //   })
      // }
    }
  }
  return {
    handleSelectBetting,
    handleSelectBettingFromBoard,
    handleGetRateInfoList
  }
}
