import { UseAdminConfigResult } from './useAdminConfig'

type MenuTransferProps = {
  data: UseAdminConfigResult
}

const menuImages: Record<string, string> = {
  deposit: '/assets/images/icons/payin.png',
  withdrawal: '/assets/images/icons/payout.png',
  message: '/assets/images/icons/note (1).png',
  money_transfer: '/assets/images/icons/moneymove.png',
  coupon_point: '/assets/images/icons/coupon.png',
  betting_history: '/assets/images/icons/bethistory.png',
  friend_recommendation: '/assets/images/icons/friend.png',
  event: '/assets/images/icons/event (1).png',
  notice: '/assets/images/icons/notice (1).png',
  transaction_history: '/assets/images/icons/cash.png',
  hall_of_fame: '/assets/images/icons/event (2).png',
  attendance_status: '/assets/images/icons/attendance.png',
  casino: '/assets/images/icons/casino.png',
  slot: '/assets/images/icons/casino.png',
  chat11: '/assets/images/icons/question.png',
  sports: '/assets/images/icons/sports.png',
  live: '/assets/images/icons/inplay.png',
  virtual_sports: '/assets/images/icons/inplay.png',
  mini_game: '/assets/images/icons/virtual.png',
  inquiry: '/assets/images/icons/question.png',
  bti: '/assets/images/icons/sports.png'
}

const specialMenu = ['bti']

const useMenuTransfer = (props: MenuTransferProps): UseAdminConfigResult => {
  const { data } = props

  const addImageToMenu = (menu?: any[]) =>
    menu?.map((item) => ({
      ...item,
      image: item.image || menuImages[item.key],
      ...(specialMenu.includes(item?.key) && {
        isSpecial: true,
        providerCode: item?.link?.replace(/[^a-zA-Z0-9]/g, '')
      })
    }))

  const navigateLinks = data.navbarSettings?.navigateLinks?.map((item) => ({
    ...item,
    ...(specialMenu.includes(item?.key) && { isSpecial: true, providerCode: item?.link?.replace(/[^a-zA-Z0-9]/g, '') })
  }))

  return {
    ...data,
    navbarSettings: {
      ...data.navbarSettings,
      navigateLinks
    },
    sidebarSettings: {
      ...data.sidebarSettings,
      leftSidebarLinks: addImageToMenu(data.sidebarSettings?.leftSidebarLinks),
      bottomLeftMenu: addImageToMenu(data.sidebarSettings?.bottomLeftMenu),
      topLeftMenu: addImageToMenu(data.sidebarSettings?.topLeftMenu),
      mobileLinks: addImageToMenu(data.sidebarSettings?.mobileLinks),
      topSidebarLinks: addImageToMenu(data.sidebarSettings?.topSidebarLinks)
    }
  }
}

export default useMenuTransfer
