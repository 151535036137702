import { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useSportSearchStore } from 'src/libs/hooks/store/useSportSearchStore'
import { RootState } from 'src/libs/utils/store'

declare global {
  interface Window {
    STATSCOREWidgets: any
  }
}

const LivematchProWidget = () => {
  const widgetRef = useRef<any>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [isError, setIsError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { dataRateInfoList } = useSportSearchStore()
  const { eventId } = useSelector((state: RootState) => state.sportSearch)

  const initializeWidget = useCallback(async () => {
    setIsLoading(true)

    if (widgetRef.current) {
      try {
        await widgetRef.current.destroy()
        console.log('Widget destroyed successfully')
      } catch (error) {
        console.error('Failed to destroy widget:', error)
      }
    }

    window.STATSCOREWidgets.onLoad((err: any) => {
      if (err) {
        console.error('Failed to load STATSCOREWidgets:', err)
        setIsError(true)
        setIsLoading(false)
        return
      }

      const container = containerRef.current
      if (container) {
        try {
          const configurationId = process.env.REACT_APP_CONFIGURATION_LIVE_ID
          const options = {}
          const inputData = {
            eventId: `m:${eventId}`,
            language: 'ko'
          }
          const widget = new window.STATSCOREWidgets.WidgetGroup(container, configurationId, inputData, options)
          widgetRef.current = widget

          widget.on('beforeInsert', () => {
            console.log('Widget loading')
          })
          widget.on('mount', () => {
            console.log('Widget loaded')
            setIsError(false)
            setIsLoading(false)
          })
          widget.on('error', (e: any) => {
            console.error('Widget error:', e)
            setIsError(true)
            setIsLoading(false)
          })
        } catch (error) {
          console.error('Failed to initialize widget:', error)
          setIsError(true)
          setIsLoading(false)
        }
      } else {
        console.error('Element with id "widget-container-sport" not found')
        setIsError(true)
        setIsLoading(false)
      }
    })
  }, [eventId])

  useLayoutEffect(() => {
    initializeWidget()

    return () => {
      if (widgetRef.current) {
        widgetRef.current
          .destroy()
          .then(() => {
            console.log('Widget destroyed on unmount')
          })
          .catch((error: any) => {
            console.error('Failed to destroy widget on unmount:', error)
          })
      }
    }
  }, [initializeWidget])

  // if (isLoading) {
  //   return (
  //     <div className='bg-[#333] flex items-center justify-center h-[500px] w-full'>
  //       <Spin />
  //     </div>
  //   )
  // }

  if (isError) {
    return (
      <div className='bg-[#000] flex flex-col items-center justify-center h-[500px] w-full livematch-content text-black'>
        <div className='flex items-center gap-2 text-white'>
          <span>{dataRateInfoList[0]?.home_team}</span>
          <span>vs</span>
          <span>{dataRateInfoList[0]?.away_team}</span>
        </div>
      </div>
    )
  }

  // if (isError) {
  //   return <div className='hidden'></div>
  // }

  return <div id='widget-container-sport' ref={containerRef}></div>
}

export default LivematchProWidget
