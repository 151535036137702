import { TableColumnsType } from 'antd'
import DataTable from 'src/components/Table/DataTable'

export const topLeftSidebar = [
  {
    name: '순위',
    icon: '/assets/images/icons/payin.png'
  },
  {
    name: '아이디',
    icon: '/assets/images/icons/payout.png'
  },
  {
    name: '당첨금액',
    icon: '/assets/images/icons/note (1).png'
  },
  {
    name: '보너스 예정 지급액',
    icon: '/assets/images/icons/question.png'
  }
]
const FameColumns: TableColumnsType = [
  {
    title: '순위',
    dataIndex: 'src',
    key: 'src',
    width: '10%',
    align: 'center'
  },
  {
    title: '아이디',
    dataIndex: 'user',
    key: 'user',
    width: '50%',
    align: 'center'
  },
  {
    title: '당첨금액',
    dataIndex: 'win',
    key: 'win',
    width: '20%',
    align: 'center'
  },
  {
    title: '보너스 예정 지급액',
    dataIndex: 'wincash',
    key: 'wincash',
    width: '20%',
    align: 'center'
  }
]

const data = [
  {
    key: 1,
    win: 2222,
    wincash: 1100,
    user: 'user',
    src: (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={`/assets/images/fame/1.png`} alt={`Rank 1`} width='30px' />
      </div>
    )
  },
  {
    key: 2,
    title: 'ntTitle',
    win: 2222,
    wincash: 1100,
    user: 'user',
    src: (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={`/assets/images/fame/2.png`} alt={`Rank 2`} width='30px' />
      </div>
    )
  },
  {
    key: 3,
    title: 'ntTitle',
    win: 2222,
    wincash: 1100,
    user: 'user',
    src: (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={`/assets/images/fame/3.png`} alt={`Rank 3`} width='30px' />
      </div>
    )
  },
  {
    key: 4,
    title: 'ntTitle',
    win: 2222,
    wincash: 1100,
    user: 'user',
    src: (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={`/assets/images/fame/4.png`} alt={`Rank 4`} width='30px' />
      </div>
    )
  },
  {
    key: 5,
    title: 'ntTitle',
    win: 2222,
    wincash: 1100,
    user: 'user',
    src: (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={`/assets/images/fame/5.png`} alt={`Rank 5`} width='30px' style={{ textAlign: 'center' }} />
      </div>
    )
  }
]
export default function Fame() {
  return (
    <div className='w-full flex flex-col items-center'>
      <div className='animate-fadeInUpBig panel panel-inverse panel-page-yellow15'>
        <div className='panel-body h-full p-2 bg-gray-900 text-white relative'>
          <div className='w-full'>
            <div className='w-full'>
              <div className='p-0 w-full'>
                <img src='/assets/images/fame/banner-yellow15.png' alt='Banner' className='w-full' />
              </div>
              <div className='w-full px-4'>
                <div className='w-full mt-4 mb-4 border-t border-b'></div>
                <div className='w-full text-sm'>
                  명예의전당 순위는 1시간 마다 업데이트되며 매일 자정을 기준으로 최종 업데이트 됩니다.
                  <br />
                  익일 문의주시면 각 순위에 맞게 보너스 머니를 지급해드립니다.
                </div>
                <div className='w-full font-bold text-2xl mt-8 mb-4'>7월 19일 명예의전당</div>
              </div>
              <div className='w-full'>
                <div className='p-4 mt-2 mb-4 flex items-center bg-yellow-500 rounded'>
                  <img src='/assets/images/fame/winmoney.png' alt='Win Money' className='mr-2' />
                  <div className='text-lg font-bold pt-1 ml-4 text-white'>당첨금액 순위 TOP 5</div>
                  <div className='pt-1 text-sm ml-5 text-white'>당일 가장 높은 당첨금액에 당첨된 5명의 유저</div>
                </div>

                <DataTable
                  columns={FameColumns}
                  dataSource={data}
                  //   loading={loading}
                />
              </div>
              <div className='w-full'>
                <div className='p-4 mt-2 mb-4 flex items-center bg-yellow-500 rounded'>
                  <img src='/assets/images/fame/rolling.png' alt='Rolling' className='mr-2' />
                  <div className='text-lg font-bold pt-1 ml-4 text-white'>롤링금액 순위 TOP 5</div>
                  <div className='pt-1 text-sm ml-5 text-white'>당일 가장 높은 누적 배팅금액을 보유한 5명의 유저</div>
                </div>
                <DataTable
                  columns={FameColumns}
                  dataSource={data}
                  //   loading={loading}
                />
              </div>
              <div className='fixed top-0 left-0 w-full h-full p-0 m-0 hidden'>
                <div className='absolute top-0 left-0 w-full h-full bg-black opacity-75'></div>
                <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center'>
                  <div className='w-96 p-8 bg-gray-800 rounded-lg shadow-lg flex flex-col items-center'>
                    <img src='/assets/images/fame/banner-yellow15.png' alt='Preview' className='mt-9' width='150px' />
                    <div className='text-white mt-4'>로그인 후 이용할 수 있습니다.</div>
                    <button className='mt-9 w-full bg-yellow-500 text-black font-semibold py-2 rounded'>로그인</button>
                    <button className='mt-2 w-full bg-gray-700 text-white font-semibold py-2 rounded'>회원가입</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
