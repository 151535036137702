type EventCard = {
  title: number
  banner: string
  id?: number
  index?: number
  startDate?: string
  endDate?: string
  titleEvent?: string
  handleOnClick: (data: number) => void
}
export default function EventCard(props: EventCard) {
  return (
    <button
      className='relative cursor-pointer w-full h-full flex border border-primary rounded-lg'
      onClick={() => {
        props.id && props.handleOnClick(props.id)
      }}
    >
      <div className='text-left h-full flex flex-col justify-between rounded-lg overflow-hidden'>
        <img
          src={props.banner}
          alt={props.titleEvent}
          title={props.titleEvent}
          className='w-full h-auto border-b border-primary object-contain'
          onError={(e) => {
            e.currentTarget.src = '/assets/images/home/mobile-event.png'
            e.currentTarget.onerror = null
          }}
        />
        <div className='p-2 py-3'>
          <div className='flex flex-col lg:flex-column justify-between items-center gap-2'>
            <p className='text-14 lg:text-[18px] text-primary font-bold mb-1'>{props.titleEvent}</p>
            <div className='p-2.5 rounded-lg lg:rounded-[20px] bg-primary text-12 lg:text-[15px]'>
              <p>상세보기</p>
            </div>
          </div>
        </div>
      </div>
    </button>
  )
}
