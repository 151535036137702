/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import path from 'src/constants/path'
import { GET_MINI_GAME_NEW } from 'src/libs/apis/graphql/queries/game.query'
import { GET_LOGO_PRIORITY_QUERY, GET_PAGE_SITE_QUERY } from 'src/libs/apis/graphql/queries/page.query'
import { useCartMiniGameStore } from 'src/libs/hooks/store/useCartMiniGameStore'
import { useDocumentVisibility } from 'src/libs/hooks/useDocumentVisibility'
import { addItemToCartMiniGame, BetMiniGameItem } from 'src/libs/stores/minigame.reducer'
import { removeAllCartSport } from 'src/libs/stores/sport.reducer'
import { usePusher } from 'src/providers/PusherProvider'
import cn from 'classnames'
import { NotFoundState } from '../NotFoundState'
export interface GET_MINI_GAME {
  // TOOD: refactor
  BoStartQuery: BoStartQuery
}

export interface BoStartQuery {
  odd_even: OddEven
  left_right: LeftRight
  line: Line
  complex: Complex
  round_id: string
  start_time: number
  end_time: number
  now: number
  blocking_time?: number
}

export interface OddEven {
  odd: Option
  even: Option
}

export interface LeftRight {
  left: Option
  right: Option
}

export interface Line {
  line3: Option
  line4: Option
}

export interface Complex {
  left_3_even: Option
  right_3_odd: Option
  left_4_odd: Option
  right_4_even: Option
}

export interface Option {
  text: string
  rate: number
  transId: string
  color: string
}

type Props = {
  tabActive?: number
  handleLockedPlay?: () => void
  handleUnlockedPlay?: () => void
  refreshHistory?: () => Promise<void>
}

function formatTimeFromSeconds(totalSeconds: number) {
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}

export const koreanImages = {
  좌: '/assets/images/minigame/left.png',
  우: '/assets/images/minigame/right.png',
  '3줄': '/assets/images/minigame/line3.png',
  '4줄': '/assets/images/minigame/line4.png',
  홀: '/assets/images/minigame/odd.png',
  짝: '/assets/images/minigame/even.png',
  // '3': '/assets/images/minigame/line3.png',
  // '4': '/assets/images/minigame/line4.png',
  언더: '/assets/images/minigame/under.png',
  오버: '/assets/images/minigame/over.png',
  오바: '/assets/images/minigame/over.png',
  plus: '/assets/images/minigame/plus.png',
  '0': '/assets/images/minigame/0.png',
  '1': '/assets/images/minigame/1.png',
  '2': '/assets/images/minigame/2.png',
  '3': '/assets/images/minigame/3.png',
  '4': '/assets/images/minigame/4.png',
  '5': '/assets/images/minigame/5.png',
  '6': '/assets/images/minigame/6.png',
  '7': '/assets/images/minigame/7.png',
  '8': '/assets/images/minigame/8.png',
  '9': '/assets/images/minigame/9.png',
  대: '/assets/images/minigame/big.png',
  중: '/assets/images/minigame/middle.png',
  소: '/assets/images/minigame/small.png',
  '1번': '/assets/images/minigame/first.png',
  '2번': '/assets/images/minigame/second.png',
  '3번': '/assets/images/minigame/third.png',
  '4번': '/assets/images/minigame/forth.png'
}

export default function SideRightMiniGame({
  tabActive,
  handleLockedPlay = () => {},
  handleUnlockedPlay = () => {},
  refreshHistory = async () => {}
}: Props) {
  const pusher = usePusher()
  const dispatch = useDispatch()
  const [countdownTimer, setCountdownTimer] = useState<number | null>(null)
  const [isRefetchApi, setIsRefetchApi] = useState(false)
  const [timerServer, setTimerServer] = useState({
    startTime: 0,
    endTime: 0,
    now: 0
  })
  const [dataMiniGame, setDataMiniGame] = useState<GET_MINI_GAME | null>(null)
  const location = useLocation()

  const [minigameName, setMiniGameName] = useState('')
  const nameMiniGame = () => {
    switch (location.pathname) {
      case path.mini_boscore_ladder1:
        return 'bo_start_1'
      case path.mini_boscore_ladder2:
        return 'bo_start_2'
      case path.mini_boscore_ladder3:
        return 'bo_start_3'
      case path.mini_power_ball:
        return 'power_ladder'
      default:
        return 'bo_start_1'
    }
  }

  const {
    data: dataMini,
    refetch: refetchGame,
    loading: loading1,
    error
  } = useQuery<GET_MINI_GAME>(GET_MINI_GAME_NEW, {
    variables: { game: minigameName },
    context: {
      apiName: 'mini-game'
    },
    onCompleted: (data) => {
      setDataMiniGame(data)
      const now = data.BoStartQuery.now
      const startTime = data.BoStartQuery.start_time
      const endTime = data.BoStartQuery.end_time
      console.log('now', data.BoStartQuery.now)
      console.log('end', data.BoStartQuery.end_time)
      console.log('time game: ', data.BoStartQuery.end_time - data.BoStartQuery.now)
      console.log('-----', location.pathname)
      setTimerServer({
        now,
        startTime,
        endTime
      })
      setCountdownTimer(Math.round(endTime - now))
      setIsRefetchApi(true)
      dispatch(removeAllCartSport())
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    skip:
      location.pathname !== path.mini_boscore_ladder1 &&
      location.pathname !== path.mini_boscore_ladder2 &&
      location.pathname !== path.mini_boscore_ladder3 &&
      location.pathname !== path.mini_power_ball &&
      minigameName !== ''
  })

  const { data: configMeta } = useQuery(GET_LOGO_PRIORITY_QUERY, { context: { apiName: 'auth' } })
  const siLogo2 = configMeta?.PageSiteQuery?.siLogo2

  useEffect(() => {
    setMiniGameName(nameMiniGame())
    // if (location.pathname === path.mini_boscore_ladder1) {
    //   refetchGame1()
    // } else if (location.pathname === path.mini_boscore_ladder2) {
    //   refetchGame2()
    // } else if (location.pathname === path.mini_boscore_ladder3) {
    //   refetchGame3()
    // }
    refetchGame()
  }, [location.pathname, refetchGame])
  useDocumentVisibility({
    cbActiveTab: refetchGame
  })

  const loading = loading1
  const data = dataMini?.BoStartQuery

  const leftRight = data?.left_right
  const line = data?.line
  const odd_even = data?.odd_even
  const complex = data?.complex
  const round_id = data?.round_id
  const blocking_time = data?.blocking_time || 30
  const { cart } = useCartMiniGameStore()

  const addToCart = ({ betMiniGameItem }: { betMiniGameItem: BetMiniGameItem }) => {
    // implement when BE implement multi choose in 1 request
    // if (cart.pick_list.length === MAXIMUM_BET_ONE_TIME) {
    //   toast.warning('Can not pick')
    // }
    dispatch(addItemToCartMiniGame(betMiniGameItem))
  }

  /**\
   * structure of data
   * leftRight: line 1
   * line: line 2
   * odd_even: line 3
   * complex: line 4
   */
  // async function handleRefetchGetMiniGame() {
  //   await refetchGetMiniGame2()
  // }

  // async function handleGetMiniGame() {
  //   await refetchGetMiniGame2()
  // }

  useEffect(() => {
    if (location.pathname === path.mini_boscore_ladder1 || location.pathname === path.mini_boscore_ladder2) {
      // handleLockedPlay()
      // return
    }
    if (error) {
      dispatch(removeAllCartSport())
      setCountdownTimer(null)
      setIsRefetchApi(false)
      return
    }
    if (countdownTimer !== null && countdownTimer <= 0) {
      handleLockedPlay()
      dispatch(removeAllCartSport())
      setCountdownTimer(null)
      setIsRefetchApi(false)
      return
    }
    if (!isRefetchApi) {
      return
    }
    if (dataMiniGame) {
      if (timerServer?.endTime && countdownTimer && countdownTimer < blocking_time) {
        handleLockedPlay()
        dispatch(removeAllCartSport())
      } else {
        handleUnlockedPlay()
      }
    }
  }, [
    countdownTimer,
    timerServer,
    dataMiniGame,
    location.pathname,
    isRefetchApi,
    handleLockedPlay,
    dispatch,
    handleUnlockedPlay,
    blocking_time,
    error
  ])

  useEffect(() => {
    // handleGetMiniGame()
    const interval = setInterval(() => {
      setCountdownTimer((prev) => {
        if (prev !== null) {
          return prev - 1
        }

        return prev
      })
    }, 1000)
    return () => clearInterval(interval)
  }, [location.pathname])

  useEffect(() => {
    const channel = pusher?.subscribe('minigame-result-channel')
    channel?.bind('minigame-result-event', async (data: { game: string }) => {
      // await handleRefetchGetMiniGame()

      // if (location.pathname === path.mini_boscore_ladder1) {
      //   refetchGame()
      // } else if (location.pathname === path.mini_boscore_ladder2) {
      //   refetchGame()
      // } else if (location.pathname === path.mini_boscore_ladder3) {
      //   refetchGame()
      // }
      const currentGameType = 'bo_start_' + tabActive || 0
      if (currentGameType === data.game) {
        await refetchGame()
        await refreshHistory()
      }
    })

    return () => {
      channel?.unbind('minigame-result-event')
      pusher?.unsubscribe('minigame-result-channel')
    }
  }, [location.pathname, refetchGame, pusher])

  return (
    <div className='flex flex-col gap-1 relative h-full'>
      {loading ? (
        <div className={'flex-auto'}>
          <div className='h-full flex items-center justify-center '>
            <img
              src={siLogo2}
              onError={(e) => {
                e.currentTarget.src = '/logo.png'
                e.currentTarget.onerror = null
              }}
              className='animate-pulse max-w-[100px]'
              alt='logo loading'
            />
          </div>
        </div>
      ) : !data ? (
        <NotFoundState message={error?.message} />
      ) : (
        <>
          <div className='grid grid-cols-2 gap-1 h-[50px] py-1 px-2'>
            <div className='grid grid-cols-[5fr_8fr]'>
              <span className='cols-span-5 bg-mini-game-table-3 flex items-center justify-center text-14 text-mini-game-table-1 text-nowrap'>
                회차
              </span>
              <span className='cols-span-8 bg-mini-game-table-4 flex items-center justify-center text-white font-bold'>
                {round_id?.split('_')[1]}
              </span>
            </div>
            <div className='grid grid-cols-[5fr_8fr]'>
              <span className='cols-span-5 bg-mini-game-table-3 flex items-center justify-center text-14 text-mini-game-table-1 text-nowrap'>
                배팅마감
              </span>
              <span className='cols-span-8 bg-mini-game-table-4 flex items-center justify-center text-white font-bold'>
                {loading ? '--:--' : countdownTimer ? formatTimeFromSeconds(countdownTimer) : '--:--'}
              </span>
            </div>
          </div>
          <div className='w-full h-px bg-mini-game-table-4'></div>
          <div className='flex flex-col gap-2'>
            <div className='grid grid-cols-1 md-up:grid-cols-[2fr_10fr] gap-4 py-1 px-2'>
              <div className='cols-span-2 flex items-center justify-center bg-mini-game-table-4 text-mini-game-table-2 text-14'>
                좌우
              </div>
              <div className='cols-span-10 grid grid-cols-2 gap-x-6 gap-y-3'>
                <button
                  onClick={() => {
                    if (leftRight?.left && round_id) {
                      addToCart({
                        betMiniGameItem: {
                          miniGameItem: {
                            rate: leftRight.left.rate,
                            text: leftRight.left.text,
                            transId: round_id,
                            category: `${leftRight.left.text}/${leftRight.right.text} 맞추기`
                          },
                          selectedKeyItem: 'left'
                        }
                      })
                    }
                  }}
                  className={cn(
                    'relative flex items-center justify-center h-24 gap-2 border border-mini-game-table-border duration-200',
                    {
                      'bg-mini-game-table-item hover:bg-mini-game-table-item-active':
                        !cart.pick_list.length ||
                        (cart.pick_list.length && cart.pick_list[0].selectedKeyItem !== 'left'),
                      'bg-mini-game-table-item-active':
                        cart.pick_list.length && cart.pick_list[0].selectedKeyItem === 'left'
                    }
                  )}
                >
                  <div className='mt-[35px]'>
                    <img src={koreanImages[leftRight?.left?.text || '좌']} alt='' className='w-[50px] h-[50px]' />
                  </div>
                  <span className='text-12 absolute w-full flex items-center justify-center h-8 bg-mini-game-table-3 top-0 group-hover:-z-10 group-[.is-active]:-z-10'>
                    {leftRight?.left.rate?.toFixed(2)}
                  </span>
                </button>
                <button
                  onClick={() => {
                    if (leftRight?.right && round_id) {
                      addToCart({
                        betMiniGameItem: {
                          miniGameItem: {
                            rate: leftRight.right.rate,
                            text: leftRight.right.text,
                            transId: round_id,
                            category: `${leftRight.left.text}/${leftRight.right.text} 맞추기`
                          },
                          selectedKeyItem: 'right'
                        }
                      })
                    }
                  }}
                  className={cn(
                    'relative flex items-center justify-center h-24 gap-2 border border-mini-game-table-border duration-200',
                    {
                      'bg-mini-game-table-item hover:bg-mini-game-table-item-active':
                        !cart.pick_list.length ||
                        (cart.pick_list.length && cart.pick_list[0].selectedKeyItem !== 'right'),
                      'bg-mini-game-table-item-active':
                        cart.pick_list.length && cart.pick_list[0].selectedKeyItem === 'right'
                    }
                  )}
                >
                  <div className='mt-[35px]'>
                    <img src={koreanImages[leftRight?.right?.text || '좌']} alt='' className='w-[50px] h-[50px]' />
                  </div>
                  <span className='text-12 absolute w-full flex items-center justify-center h-8 bg-mini-game-table-3 top-0 group-hover:-z-10 group-[.is-active]:-z-10'>
                    {leftRight?.right.rate?.toFixed(2)}
                  </span>
                </button>
              </div>
            </div>
            <div className='grid grid-cols-1 md-up:grid-cols-[2fr_10fr] gap-4 py-1 px-2'>
              <div className='cols-span-2 flex items-center justify-center bg-mini-game-table-4 text-mini-game-table-2 text-14'>
                줄수
              </div>
              <div className='cols-span-10 grid grid-cols-2 gap-x-6 gap-y-3'>
                <button
                  onClick={() => {
                    if (line?.line3 && round_id) {
                      addToCart({
                        betMiniGameItem: {
                          miniGameItem: {
                            rate: line.line3.rate,
                            text: line.line3.text,
                            transId: round_id,
                            category: `${line.line3.text}/${line.line4.text} 맞추기`
                          },
                          selectedKeyItem: 'line3'
                        }
                      })
                    }
                  }}
                  className={cn(
                    'relative flex items-center justify-center h-24 gap-2 border border-mini-game-table-border duration-200',
                    {
                      'bg-mini-game-table-item hover:bg-mini-game-table-item-active':
                        !cart.pick_list.length ||
                        (cart.pick_list.length && cart.pick_list[0].selectedKeyItem !== 'line3'),
                      'bg-mini-game-table-item-active':
                        cart.pick_list.length && cart.pick_list[0].selectedKeyItem === 'line3'
                    }
                  )}
                >
                  <div className='mt-[35px]'>
                    <img src={koreanImages[line?.line3?.text || '3줄']} alt='' className='w-[50px] h-[50px]' />
                  </div>
                  <span className='text-12 absolute w-full flex items-center justify-center h-8 bg-mini-game-table-3 top-0 group-hover:-z-10 group-[.is-active]:-z-10'>
                    {line?.line3?.rate?.toFixed(2)}
                  </span>
                </button>
                <button
                  onClick={() => {
                    if (line?.line4 && round_id) {
                      addToCart({
                        betMiniGameItem: {
                          miniGameItem: {
                            rate: line.line4.rate,
                            text: line.line4.text,
                            transId: round_id,
                            category: `${line.line4.text}/${line.line4.text} 맞추기`
                          },
                          selectedKeyItem: 'line4'
                        }
                      })
                    }
                  }}
                  className={cn(
                    'relative flex items-center justify-center h-24 gap-2 border border-mini-game-table-border duration-200',
                    {
                      'bg-mini-game-table-item hover:bg-mini-game-table-item-active':
                        !cart.pick_list.length ||
                        (cart.pick_list.length && cart.pick_list[0].selectedKeyItem !== 'line4'),
                      'bg-mini-game-table-item-active':
                        cart.pick_list.length && cart.pick_list[0].selectedKeyItem === 'line4'
                    }
                  )}
                >
                  <div className='mt-[35px]'>
                    <img src={koreanImages[line?.line4?.text || '4줄']} alt='' className='w-[50px] h-[50px]' />
                  </div>
                  <span className='text-12 absolute w-full flex items-center justify-center h-8 bg-mini-game-table-3 top-0 group-hover:-z-10 group-[.is-active]:-z-10'>
                    {line?.line4?.rate?.toFixed(2)}
                  </span>
                </button>
              </div>
            </div>
            <div className='grid grid-cols-1 md-up:grid-cols-[2fr_10fr] gap-4 py-1 px-2'>
              <div className='cols-span-2 flex items-center justify-center bg-mini-game-table-4 text-mini-game-table-2 text-14'>
                홀짝
              </div>
              <div className='cols-span-10 grid grid-cols-2 gap-x-6 gap-y-3'>
                <button
                  onClick={() => {
                    if (odd_even?.odd && round_id) {
                      addToCart({
                        betMiniGameItem: {
                          miniGameItem: {
                            rate: odd_even.odd.rate,
                            text: odd_even.odd.text,
                            transId: round_id,
                            category: `${odd_even.odd.text}/${odd_even.even.text} 맞추기`
                          },
                          selectedKeyItem: 'odd'
                        }
                      })
                    }
                  }}
                  className={cn(
                    'relative flex items-center justify-center h-24 gap-2 border border-mini-game-table-border duration-200',
                    {
                      'bg-mini-game-table-item hover:bg-mini-game-table-item-active':
                        !cart.pick_list.length ||
                        (cart.pick_list.length && cart.pick_list[0].selectedKeyItem !== 'odd'),
                      'bg-mini-game-table-item-active':
                        cart.pick_list.length && cart.pick_list[0].selectedKeyItem === 'odd'
                    }
                  )}
                >
                  <div className='mt-[35px]'>
                    <img src={koreanImages[odd_even?.odd?.text || '홀']} alt='' className='w-[50px] h-[50px]' />
                  </div>
                  <span className='text-12 absolute w-full flex items-center justify-center h-8 bg-mini-game-table-3 top-0 group-hover:-z-10 group-[.is-active]:-z-10'>
                    {odd_even?.odd?.rate?.toFixed(2)}
                  </span>
                </button>
                <button
                  onClick={() => {
                    if (odd_even?.even && round_id) {
                      addToCart({
                        betMiniGameItem: {
                          miniGameItem: {
                            rate: odd_even.even.rate,
                            text: odd_even.even.text,
                            transId: round_id,
                            category: `${odd_even.odd.text}/${odd_even.even.text} 맞추기`
                          },
                          selectedKeyItem: 'even'
                        }
                      })
                    }
                  }}
                  className={cn(
                    'relative flex items-center justify-center h-24 gap-2 border border-mini-game-table-border duration-200',
                    {
                      'bg-mini-game-table-item hover:bg-mini-game-table-item-active':
                        !cart.pick_list.length ||
                        (cart.pick_list.length && cart.pick_list[0].selectedKeyItem !== 'event'),
                      'bg-mini-game-table-item-active':
                        cart.pick_list.length && cart.pick_list[0].selectedKeyItem === 'event'
                    }
                  )}
                >
                  <div className='mt-[35px]'>
                    <img src={koreanImages[odd_even?.even?.text || '짝']} alt='' className='w-[50px] h-[50px]' />
                  </div>
                  <span className='text-12 absolute w-full flex items-center justify-center h-8 bg-mini-game-table-3 top-0 group-hover:-z-10 group-[.is-active]:-z-10'>
                    {odd_even?.even?.rate?.toFixed(2)}
                  </span>
                </button>
              </div>
            </div>
            <div className='grid grid-cols-1 md-up:grid-cols-[2fr_10fr] gap-4 py-1 px-2'>
              <div className='cols-span-2 flex items-center justify-center bg-mini-game-table-4 text-mini-game-table-2 text-14'>
                조합배팅
              </div>
              <div className='cols-span-10 grid grid-cols-2 gap-x-6 gap-y-3'>
                <button
                  onClick={() => {
                    if (complex?.left_3_even && round_id) {
                      addToCart({
                        betMiniGameItem: {
                          miniGameItem: {
                            rate: complex.left_3_even.rate,
                            text: complex.left_3_even.text,
                            transId: round_id,
                            category: '조합배팅 맞추기'
                          },
                          selectedKeyItem: 'left_3_even'
                        }
                      })
                    }
                  }}
                  className={cn(
                    'relative flex flex-col items-center justify-center h-24 gap-1 border border-mini-game-table-border duration-200',
                    {
                      'bg-mini-game-table-item hover:bg-mini-game-table-item-active':
                        !cart.pick_list.length ||
                        (cart.pick_list.length && cart.pick_list[0].selectedKeyItem !== 'left_3_even'),
                      'bg-mini-game-table-item-active':
                        cart.pick_list.length && cart.pick_list[0].selectedKeyItem === 'left_3_even'
                    }
                  )}
                >
                  <div className='flex items-center justify-center mt-[35px]'>
                    {complex?.left_3_even.text?.split(' ').map((item, indexText, textArray) => {
                      if (indexText === textArray.length - 1) {
                        return (
                          // <div
                          //   key={indexText}
                          //   className='size-8 p-2 flex items-center justify-center rounded-full'
                          //   style={{
                          //     backgroundColor: option.color?.split(' ')[indexText]
                          //   }}
                          // >
                          //   <span className='text-16 font-bold'>{item}</span>
                          // </div>
                          <img src={koreanImages[item]} key={indexText} alt='' className='w-[40px] h-[40px]' />
                        )
                      }
                      return (
                        <React.Fragment key={indexText}>
                          <img src={koreanImages[item]} alt='' className='w-[40px] h-[40px]' />
                          <img src={koreanImages['plus']} alt='' className='w-[15px] h-[15px]' />
                        </React.Fragment>
                      )
                    })}
                  </div>
                  <span className='text-12 absolute w-full flex items-center justify-center h-8 bg-mini-game-table-3 top-0 group-hover:-z-10 group-[.is-active]:-z-10'>
                    {complex?.left_3_even.rate?.toFixed(2)}
                  </span>
                </button>
                <button
                  onClick={() => {
                    if (complex?.right_3_odd && round_id) {
                      addToCart({
                        betMiniGameItem: {
                          miniGameItem: {
                            rate: complex.right_3_odd.rate,
                            text: complex.right_3_odd.text,
                            transId: round_id,
                            category: '조합배팅 맞추기'
                          },
                          selectedKeyItem: 'right_3_odd'
                        }
                      })
                    }
                  }}
                  className={cn(
                    'relative flex flex-col items-center justify-center h-24 gap-1 border border-mini-game-table-border duration-200',
                    {
                      'bg-mini-game-table-item hover:bg-mini-game-table-item-active':
                        !cart.pick_list.length ||
                        (cart.pick_list.length && cart.pick_list[0].selectedKeyItem !== 'right_3_odd'),
                      'bg-mini-game-table-item-active':
                        cart.pick_list.length && cart.pick_list[0].selectedKeyItem === 'right_3_odd'
                    }
                  )}
                >
                  <div className='flex items-center justify-center mt-[35px]'>
                    {complex?.right_3_odd.text?.split(' ').map((item, indexText, textArray) => {
                      if (indexText === textArray.length - 1) {
                        return (
                          // <div
                          //   key={indexText}
                          //   className='size-8 p-2 flex items-center justify-center rounded-full'
                          //   style={{
                          //     backgroundColor: option.color?.split(' ')[indexText]
                          //   }}
                          // >
                          //   <span className='text-16 font-bold'>{item}</span>
                          // </div>
                          <img src={koreanImages[item]} key={indexText} alt='' className='w-[40px] h-[40px]' />
                        )
                      }
                      return (
                        <React.Fragment key={indexText}>
                          <img src={koreanImages[item]} alt='' className='w-[40px] h-[40px]' />
                          <img src={koreanImages['plus']} alt='' className='w-[15px] h-[15px]' />
                        </React.Fragment>
                      )
                    })}
                  </div>
                  <span className='text-12 absolute w-full flex items-center justify-center h-8 bg-mini-game-table-3 top-0 group-hover:-z-10 group-[.is-active]:-z-10'>
                    {complex?.right_3_odd.rate?.toFixed(2)}
                  </span>
                </button>
                <button
                  onClick={() => {
                    if (complex?.left_4_odd && round_id) {
                      addToCart({
                        betMiniGameItem: {
                          miniGameItem: {
                            rate: complex.left_4_odd.rate,
                            text: complex.left_4_odd.text,
                            transId: round_id,
                            category: '조합배팅 맞추기'
                          },
                          selectedKeyItem: 'left_4_odd'
                        }
                      })
                    }
                  }}
                  className={cn(
                    'relative flex flex-col items-center justify-center h-24 gap-1 border border-mini-game-table-border duration-200',
                    {
                      'bg-mini-game-table-item hover:bg-mini-game-table-item-active':
                        !cart.pick_list.length ||
                        (cart.pick_list.length && cart.pick_list[0].selectedKeyItem !== 'left_4_odd'),
                      'bg-mini-game-table-item-active':
                        cart.pick_list.length && cart.pick_list[0].selectedKeyItem === 'left_4_odd'
                    }
                  )}
                >
                  <div className='flex items-center justify-center mt-[35px]'>
                    {complex?.left_4_odd.text?.split(' ').map((item, indexText, textArray) => {
                      if (indexText === textArray.length - 1) {
                        return (
                          // <div
                          //   key={indexText}
                          //   className='size-8 p-2 flex items-center justify-center rounded-full'
                          //   style={{
                          //     backgroundColor: option.color?.split(' ')[indexText]
                          //   }}
                          // >
                          //   <span className='text-16 font-bold'>{item}</span>
                          // </div>
                          <img src={koreanImages[item]} key={indexText} alt='' className='w-[40px] h-[40px]' />
                        )
                      }
                      return (
                        <React.Fragment key={indexText}>
                          <img src={koreanImages[item]} alt='' className='w-[40px] h-[40px]' />
                          <img src={koreanImages['plus']} alt='' className='w-[15px] h-[15px]' />
                        </React.Fragment>
                      )
                    })}
                  </div>
                  <span className='text-12 absolute w-full flex items-center justify-center h-8 bg-mini-game-table-3 top-0 group-hover:-z-10 group-[.is-active]:-z-10'>
                    {complex?.left_4_odd.rate?.toFixed(2)}
                  </span>
                </button>
                <button
                  onClick={() => {
                    if (complex?.right_4_even && round_id) {
                      addToCart({
                        betMiniGameItem: {
                          miniGameItem: {
                            rate: complex.right_4_even.rate,
                            text: complex.right_4_even.text,
                            transId: round_id,
                            category: '조합배팅 맞추기'
                          },
                          selectedKeyItem: 'right_4_even'
                        }
                      })
                    }
                  }}
                  className={cn(
                    'relative flex flex-col items-center justify-center h-24 gap-1 border border-mini-game-table-border duration-200',
                    {
                      'bg-mini-game-table-item hover:bg-mini-game-table-item-active':
                        !cart.pick_list.length ||
                        (cart.pick_list.length && cart.pick_list[0].selectedKeyItem !== 'right_4_even'),
                      'bg-mini-game-table-item-active':
                        cart.pick_list.length && cart.pick_list[0].selectedKeyItem === 'right_4_even'
                    }
                  )}
                >
                  <div className='flex items-center justify-center mt-[35px]'>
                    {complex?.right_4_even.text?.split(' ').map((item, indexText, textArray) => {
                      if (indexText === textArray.length - 1) {
                        return (
                          // <div
                          //   key={indexText}
                          //   className='size-8 p-2 flex items-center justify-center rounded-full'
                          //   style={{
                          //     backgroundColor: option.color?.split(' ')[indexText]
                          //   }}
                          // >
                          //   <span className='text-16 font-bold'>{item}</span>
                          // </div>
                          <img src={koreanImages[item]} key={indexText} alt='' className='w-[40px] h-[40px]' />
                        )
                      }
                      return (
                        <React.Fragment key={indexText}>
                          <img src={koreanImages[item]} alt='' className='w-[40px] h-[40px]' />
                          <img src={koreanImages['plus']} alt='' className='w-[15px] h-[15px]' />
                        </React.Fragment>
                      )
                    })}
                  </div>
                  <span className='text-12 absolute w-full flex items-center justify-center h-8 bg-mini-game-table-3 top-0 group-hover:-z-10 group-[.is-active]:-z-10'>
                    {complex?.right_4_even.rate?.toFixed(2)}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )

  // return (
  //   <div className='bg-[#111111] w-full h-full flex flex-col'>
  //     {loading && !data ? (
  //       <div className={'flex-auto'}>
  //         <div className='h-full flex items-center justify-center '>
  //           <img src={siLogo2} className='animate-pulse max-w-[100px]' alt='logo loading' />
  //         </div>
  //       </div>
  //     ) : (
  //       <div className='bg-[#0d1122] flex-1'>
  //         <div className='mini-game-border'></div>
  //         <div className='flex items-center justify-between gap-2 w-full my-2 px-2'>
  //           <div className='flex h-12 w-1/2'>
  //             <div className='w-2/5 bg-[#3e4c6f] flex justify-center items-center h-full'>
  //               <p className='text-nowrap md:text-wrap'>회차</p>
  //             </div>
  //             <div className='bg-[#182037] w-full flex justify-center items-center h-full'>
  //               {round_id?.split('_')[1]}
  //             </div>
  //           </div>
  //           <div className='flex h-12 w-1/2'>
  //             <div className='w-2/5 bg-[#3e4c6f] flex justify-center items-center h-full'>
  //               <p className='text-nowrap md:text-wrap p-1 md:p-0'>배팅마감</p>
  //             </div>
  //             <div className='bg-[#182037] w-full flex justify-center items-center h-full'>
  //               {loading ? '--:--' : countdownTimer ? formatTimeFromSeconds(countdownTimer) : '--:--'}
  //             </div>
  //           </div>
  //         </div>
  //         <div className='mini-game-border'></div>

  //         {/* <div className='flex items-center justify-between gap-1 p-2 w-full'>
  //           <button
  //             className={`flex w-1/2 bg-[#674836] rounded h-16 hover:border hover:border-primary justify-center items-center ${tab === 0 ? 'border border-primary' : ''}`}
  //             onClick={() => setTab(0)}
  //           >
  //             일반배팅
  //           </button>
  //           <button
  //             className={`flex w-1/2 bg-[#674836] rounded h-16 hover:border hover:border-primary justify-center items-center ${tab === 1 ? 'border border-primary' : ''}`}
  //             onClick={() => setTab(1)}
  //           >
  //             조합배팅
  //           </button>
  //         </div> */}
  //         <div className='flex gap-2 justify-between py-4 border-b border-b-[#495781]'>
  //           <div className='bg-[#182037] border border-[#3e4c6f] flex justify-center items-center w-1/5'>좌우</div>
  //           <div className='grid grid-cols-2 gap-2 w-full h-full'>
  //             <div
  //               className={`h-full w-full cursor-pointer hover:bg-[#182037] border border-[#3e4c6f] ${cart.pick_list.length && cart.pick_list[0].selectedKeyItem === 'left' ? '!bg-primary' : 'bg-[#0d1122]'}`}
  //               onClick={() => {
  //                 // addToCart({
  //                 //   left: {
  //                 //     ...leftRight!.left,
  //                 //     category: '좌우',
  //                 //     details: `분 별다리3|${leftRight!.left?.text}`,
  //                 //     transId: round_id || ''
  //                 //   }
  //                 // })
  //                 if (leftRight?.left && round_id) {
  //                   addToCart({
  //                     betMiniGameItem: {
  //                       miniGameItem: {
  //                         rate: leftRight.left.rate,
  //                         text: leftRight.left.text,
  //                         transId: round_id,
  //                         category: `${leftRight.left.text}/${leftRight.right.text} 맞추기`
  //                       },
  //                       selectedKeyItem: 'left'
  //                     }
  //                   })
  //                 }
  //               }}
  //             >
  //               <p className='text-center bg-[#3e4c6f] text-12 py-1'>{leftRight?.left?.rate}</p>
  //               <div>
  //                 {leftRight?.left?.text
  //                   .split('')
  //                   .map((item, index) => (
  //                     <img src={koreanImages[item]} alt='' key={index} className='size-[36px] mx-auto mt-2' />
  //                   ))}
  //               </div>
  //             </div>
  //             <div
  //               className={`h-full w-full cursor-pointer hover:bg-[#182037] border border-[#3e4c6f] ${cart.pick_list.length && cart.pick_list[0].selectedKeyItem === 'right' ? '!bg-primary' : 'bg-[#0d1122]'}`}
  //               onClick={() => {
  //                 // addToCart({
  //                 //   right: {
  //                 //     ...leftRight!.right,
  //                 //     category: '좌우',
  //                 //     details: `분 별다리3|${leftRight!.right?.text}`,
  //                 //     transId: round_id || ''
  //                 //   }
  //                 // })
  //                 if (leftRight?.right && round_id) {
  //                   addToCart({
  //                     betMiniGameItem: {
  //                       miniGameItem: {
  //                         rate: leftRight.right.rate,
  //                         text: leftRight.right.text,
  //                         transId: round_id,
  //                         category: `${leftRight.left.text}/${leftRight.right.text} 맞추기`
  //                       },
  //                       selectedKeyItem: 'right'
  //                     }
  //                   })
  //                 }
  //               }}
  //             >
  //               <p className='text-center bg-[#3e4c6f] text-12 py-1'>{leftRight?.right?.rate}</p>
  //               {
  //                 <div>
  //                   {leftRight?.right?.text
  //                     .split('')
  //                     .map((item, index) => (
  //                       <img src={koreanImages[item]} alt='' key={index} className='size-[36px] mx-auto mt-2' />
  //                     ))}
  //                 </div>
  //               }
  //             </div>
  //           </div>
  //         </div>
  //         <div className='flex gap-2 justify-between py-4 border-b border-b-[#495781]'>
  //           <div className='bg-[#182037] border border-[#3e4c6f] flex justify-center items-center w-1/5'>줄수</div>
  //           <div className='grid grid-cols-2 gap-2 w-full h-full '>
  //             <div
  //               className={`h-full w-full cursor-pointer hover:bg-[#182037] border border-[#3e4c6f] ${cart.pick_list.length && cart.pick_list[0].selectedKeyItem === 'line3' ? '!bg-primary' : 'bg-[#0d1122]'}`}
  //               onClick={() => {
  //                 // addToCart({
  //                 //   line3: {
  //                 //     ...line!.line3,
  //                 //     category: '줄수',
  //                 //     details: `분 별다리3|${line!.line3?.text}`,
  //                 //     transId: round_id || ''
  //                 //   }
  //                 // })
  //                 if (line?.line3 && round_id) {
  //                   addToCart({
  //                     betMiniGameItem: {
  //                       miniGameItem: {
  //                         rate: line.line3.rate,
  //                         text: line.line3.text,
  //                         transId: round_id,
  //                         category: `${line.line3.text}/${line.line4.text} 맞추기`
  //                       },
  //                       selectedKeyItem: 'line3'
  //                     }
  //                   })
  //                 }
  //               }}
  //             >
  //               <p className='text-center bg-[#3e4c6f] text-12 py-1'>{line?.line3?.rate}</p>
  //               <img src={koreanImages[line?.line3?.text]} alt='' className='size-[36px] mx-auto mt-2' />
  //             </div>
  //             <div
  //               className={`h-full w-full cursor-pointer hover:bg-[#182037] border border-[#3e4c6f] ${cart.pick_list.length && cart.pick_list[0].selectedKeyItem === 'line4' ? '!bg-primary' : 'bg-[#0d1122]'}`}
  //               onClick={() => {
  //                 // addToCart({
  //                 //   line4: {
  //                 //     ...line!.line4,
  //                 //     category: '줄수',
  //                 //     details: `분 별다리3|${line!.line4?.text}`,
  //                 //     transId: round_id || ''
  //                 //   }
  //                 // })
  //                 if (line?.line4 && round_id) {
  //                   addToCart({
  //                     betMiniGameItem: {
  //                       miniGameItem: {
  //                         rate: line.line4.rate,
  //                         text: line.line4.text,
  //                         transId: round_id,
  //                         category: `${line.line3.text}/${line.line4.text} 맞추기`
  //                       },
  //                       selectedKeyItem: 'line4'
  //                     }
  //                   })
  //                 }
  //               }}
  //             >
  //               <p className='text-center bg-[#3e4c6f] text-12 py-1'>{line?.line4.rate}</p>

  //               <img src={koreanImages[line?.line4?.text]} alt='' className='size-[36px] mx-auto mt-2' />
  //             </div>
  //           </div>
  //         </div>
  //         <div className='flex gap-2 justify-between py-4 border-b border-b-[#495781]'>
  //           <div className='bg-[#182037] border border-[#3e4c6f] flex justify-center items-center w-1/5'>홀짝</div>
  //           <div className='grid grid-cols-2 gap-2 w-full h-full'>
  //             <div
  //               className={`h-full w-full cursor-pointer hover:bg-[#182037] border border-[#3e4c6f] ${cart.pick_list.length && cart.pick_list[0].selectedKeyItem === 'odd' ? '!bg-primary' : 'bg-[#0d1122]'}`}
  //               onClick={() => {
  //                 // addToCart({
  //                 //   odd: {
  //                 //     ...odd_even!.odd,
  //                 //     category: '홀짝',
  //                 //     details: `분 별다리3|${odd_even!.odd?.text}`,
  //                 //     transId: round_id || ''
  //                 //   }
  //                 // })
  //                 if (odd_even?.odd && round_id) {
  //                   addToCart({
  //                     betMiniGameItem: {
  //                       miniGameItem: {
  //                         rate: odd_even.odd.rate,
  //                         text: odd_even.odd.text,
  //                         transId: round_id,
  //                         category: `${odd_even.odd.text}/${odd_even.even.text} 맞추기`
  //                       },
  //                       selectedKeyItem: 'odd'
  //                     }
  //                   })
  //                 }
  //               }}
  //             >
  //               <p className='text-center bg-[#3e4c6f] text-12 py-1'>{odd_even?.odd?.rate}</p>
  //               <img src={koreanImages[odd_even?.odd?.text]} className='size-[36px] mx-auto mt-2' />
  //               {/* <p className='text-center text-[#674836] text-12 py-1'>81-130</p> */}
  //             </div>
  //             <div
  //               className={`h-full w-full cursor-pointer hover:bg-[#182037] border border-[#3e4c6f] ${cart.pick_list.length && cart.pick_list[0].selectedKeyItem === 'even' ? '!bg-primary' : 'bg-[#0d1122]'}`}
  //               onClick={() => {
  //                 // addToCart({
  //                 //   even: {
  //                 //     ...odd_even!.even,
  //                 //     category: '홀짝',
  //                 //     details: `분 별다리3|${odd_even!.even?.text}`,
  //                 //     transId: round_id || ''
  //                 //   }
  //                 // })
  //                 if (odd_even?.even && round_id) {
  //                   addToCart({
  //                     betMiniGameItem: {
  //                       miniGameItem: {
  //                         rate: odd_even.even.rate,
  //                         text: odd_even.even.text,
  //                         transId: round_id,
  //                         category: `${odd_even.odd.text}/${odd_even.even.text} 맞추기`
  //                       },
  //                       selectedKeyItem: 'even'
  //                     }
  //                   })
  //                 }
  //               }}
  //             >
  //               <p className='text-center bg-[#3e4c6f] text-12 py-1'>{odd_even?.even?.rate}</p>
  //               <img src={koreanImages[odd_even?.even?.text]} alt='' className='size-[36px] mx-auto mt-2' />
  //               {/* <p className='text-center text-[#674836] text-12 py-1'>81-130</p> */}
  //             </div>
  //           </div>
  //         </div>
  //         <div className='flex gap-2 justify-between py-4 border-b border-b-[#495781]'>
  //           <div className='bg-[#182037] border border-[#3e4c6f] flex items-center justify-center w-1/5 text-nowrap p-1 md:p-0'>
  //             조합배팅
  //           </div>
  //           <div className='w-full flex flex-col gap-2'>
  //             <div className='grid grid-cols-2 gap-2 w-full h-full'>
  //               <div
  //                 className={`h-full w-full cursor-pointer hover:bg-[#182037] border border-[#3e4c6f] ${cart.pick_list.length && cart.pick_list[0].selectedKeyItem === 'left_3_even' ? '!bg-primary' : 'bg-[#0d1122]'}`}
  //                 onClick={() => {
  //                   // addToCart({
  //                   //   left_3_even: {
  //                   //     ...complex!.left_3_even,
  //                   //     category: '조합배팅',
  //                   //     details: `분 별다리3|${complex!.left_3_even?.text}`,
  //                   //     transId: round_id || ''
  //                   //   }
  //                   // })
  //                   if (complex?.left_3_even && round_id) {
  //                     addToCart({
  //                       betMiniGameItem: {
  //                         miniGameItem: {
  //                           rate: complex.left_3_even.rate,
  //                           text: complex.left_3_even.text,
  //                           transId: round_id,
  //                           category: '조합배팅 맞추기'
  //                         },
  //                         selectedKeyItem: 'left_3_even'
  //                       }
  //                     })
  //                   }
  //                 }}
  //               >
  //                 <p className='text-center bg-[#3e4c6f] text-12 py-1'>{complex?.left_3_even?.rate}</p>
  //                 <div className='flex items-center justify-center py-1'>
  //                   <img
  //                     src={koreanImages[complex?.left_3_even?.text?.split(' ')?.[0]]}
  //                     alt=''
  //                     className='size-[28px] mt-1'
  //                   />
  //                   <img src={koreanImages['plus']} alt='' className='w-[12px] h-[12px] mt-1' />
  //                   <img
  //                     src={koreanImages[complex?.left_3_even?.text?.split(' ')?.[1]]}
  //                     alt=''
  //                     className='size-[28px] mt-1'
  //                   />
  //                   <img src={koreanImages['plus']} alt='' className='w-[12px] h-[12px] mt-1' />
  //                   <img
  //                     src={koreanImages[complex?.left_3_even?.text?.split(' ')?.[2]]}
  //                     alt=''
  //                     className='size-[28px] mt-1'
  //                   />
  //                 </div>
  //               </div>
  //               <div
  //                 className={`h-full w-full cursor-pointer hover:bg-[#182037] border border-[#3e4c6f] ${cart.pick_list.length && cart.pick_list[0].selectedKeyItem === 'right_3_odd' ? '!bg-primary' : 'bg-[#0d1122]'}`}
  //                 onClick={() => {
  //                   // addToCart({
  //                   //   right_3_odd: {
  //                   //     ...complex!.right_3_odd,
  //                   //     category: '조합배팅',
  //                   //     details: `분 별다리3|${complex!.right_3_odd?.text}`,
  //                   //     transId: round_id || ''
  //                   //   }
  //                   // })
  //                   if (complex?.right_3_odd && round_id) {
  //                     addToCart({
  //                       betMiniGameItem: {
  //                         miniGameItem: {
  //                           rate: complex.right_3_odd.rate,
  //                           text: complex.right_3_odd.text,
  //                           transId: round_id,
  //                           category: '조합배팅 맞추기'
  //                         },
  //                         selectedKeyItem: 'right_3_odd'
  //                       }
  //                     })
  //                   }
  //                 }}
  //               >
  //                 <p className='text-center bg-[#3e4c6f] text-12 py-1'>{complex?.right_3_odd?.rate}</p>
  //                 <div className='flex items-center justify-center py-1'>
  //                   <img
  //                     src={koreanImages[complex?.right_3_odd?.text?.split(' ')?.[0]]}
  //                     alt=''
  //                     className='size-[28px] mt-1'
  //                   />
  //                   <img src={koreanImages['plus']} alt='' className='w-[12px] h-[12px] mt-1' />
  //                   <img
  //                     src={koreanImages[complex?.right_3_odd?.text?.split(' ')?.[1]]}
  //                     alt=''
  //                     className='size-[28px] mt-1'
  //                   />
  //                   <img src={koreanImages['plus']} alt='' className='w-[12px] h-[12px] mt-1' />
  //                   <img
  //                     src={koreanImages[complex?.right_3_odd?.text?.split(' ')?.[2]]}
  //                     alt=''
  //                     className='size-[28px] mt-1'
  //                   />
  //                   {/* <p
  //                   style={{
  //                     background: complex?.right_3_odd?.color?.split(' ')?.[1]
  //                   }}
  //                   className={`border-2 border-black min-w-[36px] w-min h-[36px] mx-auto rounded-full flex justify-center items-center bg-[#674836] my-2`}
  //                 >
  //                   {complex?.right_3_odd?.text?.split(' ')?.[1]}
  //                 </p>
  //                 <p
  //                   style={{
  //                     background: complex?.right_3_odd?.color?.split(' ')?.[2]
  //                   }}
  //                   className={`border-2 border-black min-w-[36px] w-min h-[36px] mx-auto rounded-full flex justify-center items-center bg-[#674836] my-2`}
  //                 >
  //                   {complex?.right_3_odd?.text?.split(' ')?.[2]}
  //                   {console.log(complex?.right_3_odd?.text?.split(' ')?.[2])}
  //                 </p> */}
  //                 </div>
  //               </div>

  //               {/* <div className='bg-[#E2C65B] h-full w-full'>
  //                 <p className='text-center bg-[#4A3427] text-12 py-1'>1.95</p>
  //                 <p className='mx-auto w-8 h-8 rounded-full flex justify-center items-center bg-[#674836] my-2'>1</p>
  //               </div>
  //               <div className='bg-[#E2C65B] h-full w-full'>
  //                 <p className='text-center bg-[#4A3427] text-12 py-1'>1.95</p>
  //                 <p className='mx-auto w-8 h-8 rounded-full flex justify-center items-center bg-[#674836] my-2'>1</p>
  //               </div>
  //               <div className='bg-[#E2C65B] h-full w-full'>
  //                 <p className='text-center bg-[#4A3427] text-12 py-1'>1.95</p>
  //                 <p className='mx-auto w-8 h-8 rounded-full flex justify-center items-center bg-[#674836] my-2'>1</p>
  //               </div> */}
  //             </div>
  //             <div className='grid grid-cols-2 gap-2 w-full h-full'>
  //               <div
  //                 className={`h-full w-full cursor-pointer hover:bg-[#182037] border border-[#3e4c6f] ${cart.pick_list.length && cart.pick_list[0].selectedKeyItem === 'left_4_odd' ? '!bg-primary' : 'bg-[#0d1122]'}`}
  //                 onClick={() => {
  //                   // addToCart({
  //                   //   left_4_odd: {
  //                   //     ...complex!.left_4_odd,
  //                   //     category: '조합배팅',
  //                   //     details: `분 별다리3|${complex!.left_4_odd?.text}`,
  //                   //     transId: round_id || ''
  //                   //   }
  //                   // })
  //                   if (complex?.left_4_odd && round_id) {
  //                     addToCart({
  //                       betMiniGameItem: {
  //                         miniGameItem: {
  //                           rate: complex.left_4_odd.rate,
  //                           text: complex.left_4_odd.text,
  //                           transId: round_id,
  //                           category: '조합배팅 맞추기'
  //                         },
  //                         selectedKeyItem: 'left_4_odd'
  //                       }
  //                     })
  //                   }
  //                 }}
  //               >
  //                 <p className='text-center bg-[#3e4c6f] text-12 py-1'>{complex?.left_4_odd?.rate}</p>
  //                 <div className='flex items-center justify-center py-1'>
  //                   <img
  //                     src={koreanImages[complex?.left_4_odd?.text?.split(' ')?.[0]]}
  //                     alt=''
  //                     className='size-[28px] mt-1'
  //                   />
  //                   <img src={koreanImages['plus']} alt='' className='w-[12px] h-[12px] mt-1' />
  //                   <img
  //                     src={koreanImages[complex?.left_4_odd?.text?.split(' ')?.[1]]}
  //                     alt=''
  //                     className='size-[28px] mt-1'
  //                   />
  //                   <img src={koreanImages['plus']} alt='' className='w-[12px] h-[12px] mt-1' />
  //                   <img
  //                     src={koreanImages[complex?.left_4_odd?.text?.split(' ')?.[2]]}
  //                     alt=''
  //                     className='size-[28px] mt-1'
  //                   />
  //                   {/* <p
  //                   style={{
  //                     background: complex?.left_4_odd?.color.split(' ')[0]
  //                   }}
  //                   className={`border-2 border-black min-w-[36px] w-min h-[36px] mx-auto rounded-full flex justify-center items-center bg-[#674836] my-2`}
  //                 >
  //                   {complex?.left_4_odd?.text?.split(' ')?.[0]}
  //                   {console.log(complex?.left_4_odd?.text?.split(' ')?.[0])}
  //                 </p>
  //                 <p
  //                   style={{
  //                     background: complex?.left_4_odd?.color.split(' ')[1]
  //                   }}
  //                   className={`border-2 border-black min-w-[36px] w-min h-[36px] mx-auto rounded-full flex justify-center items-center bg-[#674836] my-2`}
  //                 >
  //                   {complex?.left_4_odd?.text?.split(' ')?.[1]}
  //                   {console.log(complex?.left_4_odd?.text?.split(' ')?.[1])}
  //                 </p>
  //                 <p
  //                   style={{
  //                     background: complex?.left_4_odd?.color.split(' ')[2]
  //                   }}
  //                   className={`border-2 border-black min-w-[36px] w-min h-[36px] mx-auto rounded-full flex justify-center items-center bg-[#674836] my-2`}
  //                 >
  //                   {complex?.left_4_odd?.text?.split(' ')?.[2]}
  //                   {console.log(complex?.left_4_odd?.text?.split(' ')?.[2])}
  //                 </p> */}
  //                 </div>
  //               </div>
  //               <div
  //                 className={`h-full w-full cursor-pointer hover:bg-[#182037] border border-[#3e4c6f] ${cart.pick_list.length && cart.pick_list[0].selectedKeyItem === 'right_4_even' ? '!bg-primary' : 'bg-[#0d1122]'}`}
  //                 onClick={() => {
  //                   // addToCart({
  //                   //   right_4_even: {
  //                   //     ...complex!.right_4_even,
  //                   //     category: '조합배팅',
  //                   //     details: `분 별다리3|${complex!.right_4_even?.text}`,
  //                   //     transId: round_id || ''
  //                   //   }
  //                   // })
  //                   if (complex?.right_4_even && round_id) {
  //                     addToCart({
  //                       betMiniGameItem: {
  //                         miniGameItem: {
  //                           rate: complex.right_4_even.rate,
  //                           text: complex.right_4_even.text,
  //                           transId: round_id,
  //                           category: '조합배팅 맞추기'
  //                         },
  //                         selectedKeyItem: 'right_4_even'
  //                       }
  //                     })
  //                   }
  //                 }}
  //               >
  //                 <p className='text-center bg-[#3e4c6f] text-12 py-1'>{complex?.right_4_even?.rate}</p>
  //                 <div className='flex items-center justify-center py-1'>
  //                   <img
  //                     src={koreanImages[complex?.right_4_even?.text?.split(' ')?.[0]]}
  //                     alt=''
  //                     className='size-[28px] mt-1'
  //                   />
  //                   <img src={koreanImages['plus']} alt='' className='w-[12px] h-[12px] mt-1' />
  //                   <img
  //                     src={koreanImages[complex?.right_4_even?.text?.split(' ')?.[1]]}
  //                     alt=''
  //                     className='size-[28px] mt-1'
  //                   />
  //                   <img src={koreanImages['plus']} alt='' className='w-[12px] h-[12px] mt-1' />

  //                   <img
  //                     src={koreanImages[complex?.right_4_even?.text?.split(' ')?.[2]]}
  //                     alt=''
  //                     className='size-[28px] mt-1'
  //                   />
  //                 </div>
  //               </div>

  //               {/* <div className='bg-[#E2C65B] h-full w-full'>
  //                 <p className='text-center bg-[#4A3427] text-12 py-1'>1.95</p>
  //                 <p className='mx-auto w-8 h-8 rounded-full flex justify-center items-center bg-[#674836] my-2'>1</p>
  //               </div>
  //               <div className='bg-[#E2C65B] h-full w-full'>
  //                 <p className='text-center bg-[#4A3427] text-12 py-1'>1.95</p>
  //                 <p className='mx-auto w-8 h-8 rounded-full flex justify-center items-center bg-[#674836] my-2'>1</p>
  //               </div>
  //               <div className='bg-[#E2C65B] h-full w-full'>
  //                 <p className='text-center bg-[#4A3427] text-12 py-1'>1.95</p>
  //                 <p className='mx-auto w-8 h-8 rounded-full flex justify-center items-center bg-[#674836] my-2'>1</p>
  //               </div> */}
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     )}
  //     <div className='mini-game-border'></div>
  //   </div>
  // )
}
