/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client'
import './newBoard.scss'

import { ConfigProvider, TableColumnsType, TablePaginationConfig } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CopyableCell from 'src/components/Noitice/CopyTextCell'
import NoticeCard from 'src/components/Noitice/NoticeCard'
import DataTable from 'src/components/Table/DataTable'
import path from 'src/constants/path'
import { GET_PAGE_SITE_QUERY } from 'src/libs/apis/graphql/queries/page.query'
import { NoticeType, useNoticeQuery } from 'src/libs/hooks/useNotice'
import useQueryParams from 'src/libs/hooks/useQueryParams'
import { setIsLoadingPage } from 'src/libs/stores/common.reducer'
import { convertAdminAssetUrl } from 'src/libs/utils/url'

//@Column board
const BoardColumns: TableColumnsType<NoticeType> = [
  {
    title: 'NO',
    key: 'number',
    width: '5%',
    align: 'center',
    render: (_, record, index) => {
      return <span>{index + 1}</span>
    }
  },
  {
    title: '타입',
    key: 'ntCategory',
    dataIndex: 'ntCategory',
    width: '10%',
    align: 'center',
    render: (record) => {
      switch (record) {
        case 'notice':
          return <span>공지사항</span>
        case 'faq':
          return <span>FAQ</span>
        case 'user_guide':
          return <span>이용안내</span>
        case 'betting_rules':
          return <span>배팅규정</span>
        default:
          return <span>공지사항</span>
      }
    }
  },
  {
    title: '공지/규정내용',
    dataIndex: 'ntTitle',
    key: 'ntTitle',
    width: '80%',
    align: 'center'
  }
]

export default function NewBoard() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const queryParams = useQueryParams()
  const [filter, setFilter] = useState<string>(queryParams.categoryId || 'notice')
  const [noticeDetail, setNoticeDetail] = useState<NoticeType | undefined>(undefined)
  const searchParams = new URLSearchParams(window.location.search)
  const { data: pageSiteQuery } = useQuery(GET_PAGE_SITE_QUERY, {
    context: { apiName: 'auth' }
  })

  const { data, refetch, loading } = useNoticeQuery({
    filter
  })

  const { noticeType } = data || {}

  const {
    data: noticeList,
    total,
    per_page: pageSize
  } = noticeType || { data: [], total: 0, per_page: 10, current_page: 1 }

  const handleClickNewBoard = async (id?: number) => {
    if (!id) return

    // TODO: show notice detail
  }

  useEffect(() => {
    if (loading) {
      dispatch(setIsLoadingPage(true))
    }

    return () => {
      dispatch(setIsLoadingPage(false))
    }
  }, [dispatch, loading])

  // ? watch noticeId from url. to show notice detail
  useEffect(() => {
    if (queryParams.noticeId) {
      handleClickNewBoard(Number(queryParams.noticeId))
    }
  }, [queryParams.noticeId])

  useEffect(() => {
    const notice = searchParams.get('notice')

    if (!notice) setNoticeDetail(undefined)

    if (notice) {
      setNoticeDetail(noticeList?.find((item) => item?.ntNo === Number(notice)))
    }
  }, [searchParams, noticeList])

  const handleOnClick = async (noiticeId: number) => {
    if (!noiticeId) return
    searchParams.set('notice', noiticeId.toString())
    navigate({ search: searchParams.toString() })
  }

  const renderNoticeList = () => {
    if (noticeList?.length === 0) return <h3 className='text-center col-span-3 my-5'>No notice matched</h3>

    if (noticeDetail) {
      return (
        <div className='flex flex-col'>
          <div className=''>
            <CopyableCell content={noticeDetail?.ntContent} />
          </div>
          <div className='border-b border-[#333] my-4'></div>
          <button
            className='bg-buttonLinear self-end p-2 px-6 w-fit rounded-md'
            onClick={() => {
              searchParams.set('notice', '')
              navigate(path.new_board)
            }}
          >
            목록
          </button>
        </div>
      )
    }

    return (
      <div className='grid grid-cols-2 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-5 gap-3 w-full overflow-auto event__overflow'>
        {noticeList?.map((item) => (
          <NoticeCard
            key={item?.ntNo}
            index={item?.ntNo}
            id={item?.ntNo}
            banner={item?.ntLogo ?? ''}
            title={item?.ntTitle ?? ''}
            titleEvent={''}
            handleOnClick={(e) => handleOnClick(e)}
          />
        ))}
      </div>
    )
  }

  //@Handle change pagination
  const handleChangePagination = (pagination: TablePaginationConfig) => {
    const { current: currentPage } = pagination

    refetch({
      page: currentPage || 1,
      filter
    })
  }

  return (
    <div className='board__container mt-2 px-4'>
      <div className='bg-[#222] mt-3 h-[40px] text-center text-16 leading-10'>공지/규정</div>
      <div className='board__container-table' data-aos='fade-up'>
        <ConfigProvider
          renderEmpty={() => {
            return (
              <div className='bg-[#25292c] flex items-center justify-center'>
                <img
                  src={pageSiteQuery?.PageSiteQuery?.siLogo1}
                  alt='Logo'
                  title='Logo'
                  width='118'
                  height='59'
                  onError={(e) => {
                    e.currentTarget.src = '/zero.png'
                    e.currentTarget.onerror = null
                  }}
                  className='max-h-[59px] object-contain max-w-[118px] mx-auto'
                />
              </div>
            )
          }}
        >
          <DataTable
            columns={BoardColumns}
            expandable={{
              expandedRowRender: (record: any) => <div dangerouslySetInnerHTML={{ __html: record?.ntContent }}></div>,
              onExpand: (expanded, record) => {
                handleClickNewBoard(record?.ntNo)
              },
              expandRowByClick: true,
              expandIconColumnIndex: -1,
              defaultExpandedRowKeys: [Number(queryParams?.noticeId)]
            }}
            pagination={{
              position: ['bottomCenter'],
              total: total,
              pageSize: pageSize
            }}
            rowClassName={'cursor-pointer'}
            dataSource={noticeList?.map((item) => {
              return {
                ...item,
                key: item?.ntNo
              }
            })}
            scroll={{ x: 500 }}
            onChange={handleChangePagination}
            loading={loading}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}
