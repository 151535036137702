import { Link, Outlet, useLocation } from 'react-router-dom'
import './battle.scss'
export default function Battle() {
  const location = useLocation()
  const battleFilter = [
    {
      title: '카지노',
      type: 'bet-games'
    },
    {
      title: '스포츠',
      type: 'sports'
    },
    {
      title: '미니게임',
      type: 'minigame'
    },
    {
      title: '가상스포츠',
      type: 'virtual-sports'
    }
    // {
    //   title: '카지노',
    //   type: 'casino'
    // }
  ]

  const checkPathName = (type: string) => {
    const pathName = location.pathname.split('/')[2]
    return pathName === type
  }
  return (
    <div className='battle__container mt-2  border border-[#2f2f2f] px-3 bg-black' data-aos='fade-up'>
      {/* <div className='battle__container-header p-2.5'>
        <span>배팅내역</span>
      </div> */}

      <div className='battle__container-tab'>
        {battleFilter.map((item) => {
          return (
            <Link
              to={`${item.type}`}
              className={`battle__container-tab--item ${checkPathName(item.type) ? 'active' : ''}`}
              key={item.type}
            >
              {item.title}
            </Link>
          )
        })}
      </div>
      <Outlet />
    </div>
  )
}
