import InfiniteScroll from 'react-infinite-scroll-component'
import Collapse from 'src/components/Collapse'
import { GroupSportListByKey } from 'src/types/live-sports.type'
import { GroupLiveSportList } from './GroupLiveSportList'
type Props = {
  listGroupSportByKeys: GroupSportListByKey[]
  isLoading?: boolean
}

export const GroupLiveSportByTime = ({ listGroupSportByKeys, isLoading = false }: Props) => {
  const listStartGame = listGroupSportByKeys
    .filter((i) => {
      const keyArr = i.key.split('|')
      const isStartGame = keyArr[keyArr.length - 1] === 'true' || false
      return isStartGame
    })
    .filter((i) => i.listGroupSport.length > 0)
  const listNotStartGame = listGroupSportByKeys
    .filter((i) => {
      const keyArr = i.key.split('|')
      const isStartGame = keyArr[keyArr.length - 1] === 'false' || false
      return isStartGame
    })
    .filter((i) => i.listGroupSport.length > 0)

  return (
    <div className='3xl:w-full'>
      <InfiniteScroll
        className='overflow-hidden space-y-2 3xl:w-full'
        dataLength={listGroupSportByKeys.length || 0}
        next={() => {}}
        hasMore={false}
        scrollableTarget={'main-content-sports'}
        loader={
          <div className='flex justify-center items-center w-full py-4 animate-pulse'>
            <img src='/logo.gif' alt='logo' />
          </div>
        }
      >
        {listStartGame.length > 0 && (
          <>
            {listStartGame.map((listGroupSportByKeyItem) => {
              return (
                <Collapse
                  key={listGroupSportByKeyItem.key}
                  classRoot='bg-transparent rounded-md'
                  activeDefault={true}
                  classWrapper='!bg-black-3 py-2 px-2'
                  head={
                    <div className='flex justify-between items-center w-full gap-2'>
                      <div className='flex items-center gap-1.5 2xl:gap-2 text-12'>
                        {listGroupSportByKeyItem.listGroupSport[0].location_name && (
                          <>
                            <img
                              src='/assets/images/sports/arrows_1.svg'
                              alt='icon'
                              title='icon'
                              width='10'
                              height='11'
                            />
                            {listGroupSportByKeyItem.listGroupSport[0].logo_location && (
                              <img
                                src={listGroupSportByKeyItem.listGroupSport[0].logo_location}
                                alt='icon'
                                title='icon'
                                width='18'
                                height='12'
                              />
                            )}
                            <p>{listGroupSportByKeyItem.listGroupSport[0].location_name}</p>
                          </>
                        )}
                        {listGroupSportByKeyItem.listGroupSport[0].league_name && (
                          <>
                            <img
                              src='/assets/images/sports/arrows_1.svg'
                              alt='icon'
                              title='icon'
                              width='10'
                              height='11'
                            />
                            <p>{listGroupSportByKeyItem.listGroupSport[0].league_name || ''}</p>
                          </>
                        )}
                      </div>
                      <img
                        src={listGroupSportByKeyItem.listGroupSport[0].logo_sports}
                        alt='icon'
                        title='icon'
                        className='w-[18px] h-3'
                      />
                    </div>
                  }
                >
                  <div className='mt-4'>
                    <GroupLiveSportList
                      key={listGroupSportByKeyItem.key}
                      listGroupSportByKey={listGroupSportByKeyItem}
                    />
                  </div>
                </Collapse>
              )
            })}
          </>
        )}
        {listNotStartGame.length ? (
          <div className='flex items-center justify-center py-2 !my-8 bg-gray-6'>
            <span className='text-18'>진행예정경기</span>
          </div>
        ) : null}
        {listNotStartGame.map((listGroupSportByKeyItem) => {
          return (
            <Collapse
              key={listGroupSportByKeyItem.key}
              classRoot='bg-transparent rounded-md'
              activeDefault={true}
              classWrapper='!bg-black-3 py-2 pl-2'
              head={
                <div className='flex justify-between items-center w-full gap-2'>
                  <div className='flex items-center gap-1.5 2xl:gap-2 text-12'>
                    <img
                      src={listGroupSportByKeyItem.listGroupSport[0].logo_sports}
                      alt='icon'
                      title='icon'
                      className='w-[18px] h-3'
                    />
                    <img src='/assets/images/sports/arrows_1.svg' alt='icon' title='icon' width='10' height='11' />
                    <img
                      src={listGroupSportByKeyItem.listGroupSport[0].logo_location}
                      alt='icon'
                      title='icon'
                      width='18'
                      height='12'
                    />
                    <p>{listGroupSportByKeyItem.listGroupSport[0].location_name}</p>
                    <img src='/assets/images/sports/arrows_1.svg' alt='icon' title='icon' width='10' height='11' />
                    <p>{listGroupSportByKeyItem.listGroupSport[0].league_name || ''}</p>
                  </div>
                </div>
              }
            >
              <div className='mt-4'>
                <GroupLiveSportList key={listGroupSportByKeyItem.key} listGroupSportByKey={listGroupSportByKeyItem} />
              </div>
            </Collapse>
          )
        })}
      </InfiniteScroll>
    </div>
  )
}
