/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery } from '@apollo/client'
import { Modal } from 'antd'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Button from 'src/components/ui/Button'
import { CANCEL_BET_HISTORY_MUTATION } from 'src/libs/apis/graphql/mutations/sport.mutation'
import { GET_BATTLE_HISTORY_SPORTS } from 'src/libs/apis/graphql/queries/sport.query'
import { RootState } from 'src/libs/utils/store'
import { SportsBattleType } from 'src/types/sports.type'
import SportRowItem from './SportRow'

const columns = [
  { title: '경기시간', fieldName: 'date', width: '0' },
  { title: '리그명', fieldName: 'league', width: '0' },
  { title: '홈팀vs원정팀', fieldName: 'team', width: '0' },
  { title: '경기타입', fieldName: 'time', width: '0' },
  { title: '배당', fieldName: 'ratio', width: '0' },
  { title: '배팅타입', fieldName: 'type', width: '0' },
  { title: '경기결과', fieldName: 'result', width: '0' },
  { title: '배팅결과', fieldName: 'status', width: '0' }
]

const GAME_TYPES = {
  cross: '2',
  live: '3'
}

export default function Sports() {
  const [query, setQuery] = useState({ limit: 5, page: 1, total: 0, offset: 1, game_type: GAME_TYPES.cross })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [cancelId, setCancelId] = useState('')
  const [cancelBetMutation] = useMutation(CANCEL_BET_HISTORY_MUTATION)
  const user = useSelector((state: RootState) => state.auth.user)

  const {
    data: sportsData,
    loading: loadingSports,
    refetch: refetchSportsBattle
  } = useQuery<SportsBattleType>(GET_BATTLE_HISTORY_SPORTS, {
    variables: { game_type: query.game_type ?? '', limit: query.limit ?? 10, offset: query.offset ?? 0 },
    context: { apiName: 'sports' },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !user?.mNo
  })

  const checkTimeOutAdminBet = (timeLeftDisable: any, timeLeftCancelable: any) => {
    return timeLeftDisable > 0 || timeLeftCancelable === 1
  }

  const showModal = (id: string) => {
    setIsModalOpen(true)
    setCancelId(id)
  }

  const handleOk = async () => {
    await cancelBetMutation({
      context: { apiName: 'sports' },
      variables: {
        cart_idx: cancelId
      },
      onCompleted: (data) => {
        toast.success(data?.CancelBetMutation?.message)
        refetchSportsBattle()
      },
      onError: (error) => {
        toast.error(error?.message)
      }
    })
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const itemsPerPage = query.limit
  const sportsDataBet = (!loadingSports && sportsData?.BetHistoryQuery?.data) || []
  const total = (!loadingSports && sportsData?.BetHistoryQuery?.total) || 0
  const totalPages = Math.ceil(total / itemsPerPage)

  const handlePageChange = (newPage: any | undefined) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setQuery({ ...query, offset: newPage })
      setCurrentPage(newPage)
    }
  }

  const modalExampleCancel = () => {
    return (
      <Modal
        title=''
        onCancel={handleCancel}
        open={isModalOpen}
        centered
        footer={null}
        closeIcon={false}
        rootClassName='sports-modal'
      >
        <div className='flex flex-col justify-between gap-4 p-6 relative items-center bg-black-1 border-black-2 border text-white rounded'>
          <span className='text-20 font-medium'>배팅을 취소합니다.</span>
          <div className='self-stretch flex items-center justify-center gap-4'>
            <button
              className='bg-black-4 text-white px-10 py-2 rounded hover:bg-black-2 duration-200'
              onClick={handleCancel}
            >
              취소
            </button>
            <button
              className='bg-primary text-white px-10 py-2 rounded hover:bg-primary-2 duration-200'
              onClick={handleOk}
            >
              확인
            </button>
          </div>
          <button className='absolute top-4 right-4' onClick={handleCancel}>
            <img src='/Close.png' alt='close' className='size-4' />
          </button>
        </div>
      </Modal>
    )
  }

  const handleFilter = (type: string) => {
    setQuery({ ...query, game_type: type })
  }

  return (
    <div className='battle__type mt-0 md:mt-5 xs:mt-0'>
      {modalExampleCancel()}
      <div className='flex items-center justify-between gap-2 flex-wrap'>
        <div className='flex items-center gap-2'>
          <Button
            className={`transition-all h-10 rounded bg-secondary-2 text-primary hover:bg-primary hover:text-secondary-2 w-fit px-4 min-w-20 font-medium text-14 ${query?.game_type === GAME_TYPES.cross ? '!bg-primary !text-black' : ''}`}
            onClick={() => handleFilter(GAME_TYPES.cross)}
            disabled={loadingSports}
          >
            스포츠
          </Button>
          <Button
            className={`transition-all h-10 rounded bg-secondary-2 text-primary hover:bg-primary hover:text-secondary-2 w-fit px-4 min-w-20 font-medium text-14 ${query?.game_type === GAME_TYPES.live ? '!bg-primary !text-black' : ''}`}
            onClick={() => handleFilter(GAME_TYPES.live)}
            disabled={loadingSports}
          >
            인플레이
          </Button>
        </div>
      </div>
      <div className='battle__type'>
        <div className='block border-t-2 border-primary my-2'>
          <div className='hidden md:grid md:grid-cols-8 grid-cols-5 bg-black md:min-w-[800px] min-w-0 px-2 py-4'>
            {columns.map((item, index) => {
              return (
                <h3
                  className={`flex-shrink-1 whitespace-nowrap w-full text-[14px] ${index === 0 ? 'text-left' : 'text-center'} ${index === 7 ? 'text-right pr-[22px]' : ''} ${index === 1 || index === 2 || index === 3 ? 'hidden md:block' : ''}`}
                  key={index}
                >
                  {item.title}
                </h3>
              )
            })}
          </div>

          {!loadingSports && sportsDataBet.length > 0 ? (
            sportsDataBet?.map((item, index) => (
              <SportRowItem
                key={index}
                item={item}
                index={index}
                query={query}
                showModal={showModal}
                checkTimeOutAdminBet={checkTimeOutAdminBet}
              />
            ))
          ) : loadingSports ? (
            <div className='bg-black-1 p-5 flex justify-center place-items-center'>
              <div className='text-center'>
                <img src='/logo.png' alt='loading' className='size-16' />
              </div>
            </div>
          ) : (
            <div className='bg-[#25292c] p-5 flex justify-center place-items-center'>
              <div className='text-center'>
                <p className='text-14'>해당 게임에 배팅내역이 존재하지 않습니다.</p>
              </div>
            </div>
          )}
        </div>

        {/* Pagination Controls */}
        {!loadingSports && sportsDataBet.length > 0 && (
          <div className='mt-2 mb-4 flex justify-center'>
            <button
              className={`px-4 py-2 bg-primary-10  text-white rounded ${currentPage === 1 ? 'opacity-15' : 'opacity-1'}`}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <img src='/arrow-left.svg' alt='' />
            </button>

            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                className={`w-[32px] font-dotum font-[600] text-[14px] h-[32px] rounded mx-2 ${currentPage === index + 1 ? 'bg-primary text-black' : 'bg-primary-10 text-white hover:text-primary'}`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            <button
              className={`px-4 py-2 bg-primary-10 text-white rounded ${currentPage === totalPages ? 'opacity-15' : 'opacity-1'}`}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <img src='/arrow-right.svg' alt='' />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
